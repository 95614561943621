<template>
  <!-- <div
    class="index"
    :style="{
      backgroundImage: showNav
        ? 'url(\'../assets/img/bg-pc.jpg\')'
        : 'url(\'https://69csgoskins.oss-cn-beijing.aliyuncs.com/background/h5--.png\')',
    }"
  > -->
  <div class="index">
    <!-- <div class="index"> -->
    <!-- <myinform></myinform> -->
    <!-- 公告栏 -->
    <!-- <div class="advertisement" v-if="$route.path=='/Index'">
          <div class="laba">
            <img src="./img/laba.png" alt="">
          </div>
          <div class="span">
            <div class="wenzi" v-html="advertisements"></div>
          </div>
        </div> -->
    <!-- <div class="swiper-box">
      <swiper ref="mySwiper" :options="swiperOptions" class="swiper-more">
        <swiper-slide v-for="item in bannerLists" :key = item.id>
          <a :href="item.url"><img :src="item.images" width="100%" style="display:block"/></a>
          </swiper-slide>

        <div class="swiper-pagination"></div>
      </swiper>
    </div> -->
    <!-- <CenterNav v-if="!showNav" /> -->
    <div class="center" :class="{ yidongcenter: !showNav }">
      <!-- <div class="center-Content" v-if="!showNav">
			<div class="first" v-for="(item,index) in centerContent" @click="bindNav(item.type,item.url)">
				<div><img :src="item.image" alt=""></div>
				<div>{{item.name}}</div>
			</div>
		</div> -->
      <div class="main-cese-button">
        <div class="item-box">
          <div class="item" :class="{ 'case-active': caseType == 1 }" @click="caseType = 1">{{ $t('indexTab.tab_one') }}</div>
          <div class="item" :class="{ 'case-active': caseType == 2 }" @click="caseType = 2">{{ $t('indexTab.tab_two') }}</div>
        </div>
      </div>
      <template v-if="caseType == 1">
        <div v-for="(v, i) in typeBoxList" :key="i" class="box-area">
          <div class="box">
            <div class="title">
              <h3>{{ v.type_name }}</h3>
            </div>
            <ul>
              <li
                v-for="(item, index) in v.box_list"
                :key="index"
                class="box-hover"
              >
                <div @click="openbox(item.id, v.type_name, item.box_colour)">
                  <div class="bei1">
                    <img :src="item.image" />
                  </div>
                  <div class="bei2">
                    <img v-if="item.active_image" :src="item.active_image" />
                  </div>
                  <div class="name">
                    <div class="name-warp">
                      <!--<img src="../assets/img/12mdpi.png" />-->
                      <span>{{ item.name }}</span>
                    </div>
                  </div>
                  <div class="money" v-if="v.type_name != '钥匙开箱'">
                    <div class="money-warp" :class="'color' + item.box_colour">
                      <img src="../assets/img/money.png" />
                      <span>{{ item.price }}</span>
                      <span class="money-buy" :class="'color' + item.box_colour"
                        >{{ $t("openBox.title") }}&nbsp;&nbsp;<img
                          src="../assets/img/money.png"
                        />{{ item.price }}</span
                      >
                      <!-- <span class="money-kong"></span> -->
                    </div>
                  </div>
                  <div class="money" v-if="item.name == '命悬一线武器箱'">
                    <div class="money-warp">
                      <img src="@/assets/img/key/mx.png" />
                      <span>1</span>
                      <!-- <span class="money-buy">打开</span> -->
                      <!-- <span class="money-kong"></span> -->
                    </div>
                  </div>
                  <div class="money" v-if="item.name == '手套武器箱'">
                    <div class="money-warp">
                      <img src="@/assets/img/key/st.png" />
                      <span>1</span>
                      <!-- <span class="money-buy">打开</span> -->
                      <!-- <span class="money-kong"></span> -->
                    </div>
                  </div>
                  <div class="money" v-if="item.name == '裂空武器箱'">
                    <div class="money-warp">
                      <img src="@/assets/img/key/lk.png" />
                      <span>1</span>
                      <!-- <span class="money-buy">打开</span> -->
                      <!-- <span class="money-kong"></span> -->
                    </div>
                  </div>
                  <div class="money" v-if="item.name == '狂牙武器箱'">
                    <div class="money-warp">
                      <img src="@/assets/img/key/ky.png" />
                      <span>1</span>
                      <!-- <span class="money-buy">打开</span> -->
                      <!-- <span class="money-kong"></span> -->
                    </div>
                  </div>
                </div>
              </li>
              <!-- <dir style="clear: both"></dir> -->
            </ul>
          </div>
          <div class="clear"></div>
        </div>
      </template>
    </div>
    <myhomebot></myhomebot>
    <!-- <navheader
      :showNav="showNav"
      :yidongmenu="yidongmenu"
      :openHongbao="openHongbao"
    ></navheader> -->
    <!-- <div id="mask" ref="mask" v-if="adshow && showNav">
			<img :src="adshowimg" class="mask_img" @click="adClick()" />
		</div> -->
  </div>
</template>

<script>
import Utils from "./../assets/js/util.js";
import myslide from "@/components/my_slide1.vue";
import myhomebot from "@/components/my_homebot.vue";
import myinform from "@/components/my_inform.vue";
import navheader from "@/components/navheader.vue";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import Swiper2, { Navigation, Pagination, Autoplay } from "swiper";
import { CenterNav } from "@/views/common";

Swiper2.use([Navigation, Pagination, Autoplay]);
export default {
  components: {
    myslide,
    myhomebot,
    myinform,
    Swiper,
    SwiperSlide,
    navheader,
    CenterNav,
  },
  directives: {
    swiper: directive,
  },
  props: [
    "yidongmenu",
    "showNav",
    "openHongbao",
    "bannerLists",
    "advertisements",
  ],
  data() {
    return {
      advertisement: "",
      // 轮播图数据
      bannerList: [],
      centerContent: [
        {
          name: "商城",
          image: require("../assets/img/nav2/b55.png"),
          url: "/Roll",
          type: 1,
        },
        {
          name: "roll房",
          image: require("../assets/img/nav2/b11.png"),
          url: "/Arena",
          type: 1,
        },
        {
          name: "CD-KEY",
          image: require("../assets/img/cdk.png"),
          url: "/Payment?type=cdk",
          type: 1,
        },
        {
          name: "客服",
          image: require("../assets/img/kefu.png"),
          type: 2,
        },
        {
          name: "取回助手",
          image: require("../assets/img/back.svg"),
          type: 3,
        },
      ],
      typeBoxList: [],
      listSlide: [],
      img: "",
      img1: require("../assets/img/1mdpi.png"),
      active: [],
      windowWidth: document.body.clientWidth,
      userState: "",
      banners: [
        require("../assets/img/swiper1.png"),
        require("../assets/img/swiper2.png"),
        require("../assets/img/swiper3.png"),
      ],
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
        autoplay: {
          delay: 3000, // 多长时间进行一次自动切换
          disableOnInteraction: false, // 用户点击一次后是否取消自动切换 默认true
        },
        loop: true,
      },
      adshow: false,
      adshowimg: require("../assets/img/adshow.png"),
      caseType: 1,
    };
  },
  methods: {
    bindNav(type, url) {
      if (type == 2) {
        window.open(
          "https://qm.qq.com/cgi-bin/qm/qr?k=s7qihLq_4vvaIaOwNlsGQDq6e3wSxdzM&jump_from=webapi"
        );
      }
      if (type == 1) {
        this.$router.push({
          path: url,
        });
      }
      if (type == 3) {
        this.$emit("backAction", true);
      }
    },
    adClick() {
      this.adshow = false;
      this.$store.commit("getLogin", true);
    },
    //请求背景图片
    getBack() {
      let _this = this;
      // _this.$axios.post("/index/Setting/background").then((res) => {
      //   if (res.data.status == 1) {
      //     this.img = res.data.data.img;
      //     if (!this.img) {
      //       this.img = this.img1;
      //     }
      //   }
      // });
    },
    // 首页活动模块
    getboxList() {
      let _this = this;
      _this.$axios.post("/api/index/boxList").then((res) => {
        this.active = res.data.data.active;
        if (res.data.code == 1) {
          _this.typeBoxList = res.data.data;
        }
      });
    },
    // getboxList() {
    //   let _this = this;
    //   _this.$axios.post("/index/Box/boxList").then((res) => {
    //     this.active = res.data.data.active;
    //     if (res.data.status == 1) {
    //       _this.typeBoxList = res.data.data.list;
    //     }
    //   });
    // },
    openbox(box_id, type_name, box_colour) {
      if (!this.$store.state.loginStates) {
        return this.$message({
          showClose: true,
          message: this.$t("public.noLogin"),
          type: "error",
           customClass: "message-styles"
        });
      }
      this.$router.push({
        path: `/Openbox`,
        query: {
          box_id: box_id,
          is_activity: 0,
          type_name: type_name,
          box_colour: box_colour,
        },
      });
    },
    getPlayerInfo(pid) {
      this.$axios.post("/api/player/playerdata", {}).then((res) => {
        if (res.data.code == 1) {
          localStorage.setItem("userInfo", JSON.stringify(res.data.data));
          Utils.$emit("login", true);
          that.selfLogin();
          return;
        }
      });
    },
    selfLogin() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (!userInfo) {
        return;
      }
      this.me = userInfo;
      this.money = userInfo.total_amount;
      this.masonry = userInfo.masonry;
      this.loginfalse = false;
      this.regHint = false;
    },
  },
  activated() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo) {
      this.$parent.nextLogin();
    }
  },
  mounted() {
    if (this.$route.query.pid > 0) {
      this.z(this.$route.query.pid);
    }
    this.getboxList();
    this.getBack();
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo) {
      this.adshow = true;
      return;
    }
    this.userState = userInfo.state;
    // this.$emit('backAction','click')

    // 请求轮播图数据
    this.$axios
      .post("/api/index/banner", { page: 1, pagesize: 10 })
      .then((res) => {
        if (res.data.code == 1) {
          this.bannerList = res.data.data;
        }
      });

    // 获取公告数据
    this.$axios({
      url: "/api/index/notice",
      method: "post",
    }).then((res) => {
      if (res.data.code == 1) {
        this.advertisement = res.data.data.center;
      }
    });
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
};
</script>

<style lang="less" scoped>
.swiper-box {
  overflow: hidden;
  border-radius: 8px !important;
  padding: 8px;
  // margin-top: 20px;
}
@media screen and (max-width: 567px) {
  .index {
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    width: 100%;
    // background-image: url("../assets/img/bg-move.jpg") !important;
    // background-size: 100% 100%;
    // background-position: top !important;
    // background-repeat: no-repeat;
    // background-attachment: fixed;
    // background-size: cover;
    // -webkit-background-size: cover;
    // /* 兼容Webkit内核浏览器如Chrome和Safari */
    // -o-background-size: cover;
    // /* 兼容Opera */
    // zoom: -10;
    &:before {
      content: "";
      position: fixed;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: inherit;
      -webkit-background-size: cover !important;
      -o-background-size: cover;
      background-size: cover !important;
    }
  }
}
.index {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;

  background-color: #1e2335;

  //--------
  // position:absolute;
  // top:0;
  // left:0;
  // height:calc(100vh);
  width: 100%;
  // background-image: url("../assets/img/bg-pc.jpg") !important;
  // background-size: 100% 100%;
  // background-position: 0px -300px;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  // background-size: cover;
  // -webkit-background-size: cover;
  // /* 兼容Webkit内核浏览器如Chrome和Safari */
  // -o-background-size: cover;
  // /* 兼容Opera */
  // zoom: 1;

  /* .top::-webkit-scrollbar {
        display: none;
  }*/
  // ::after {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   content: "";
  //   background-color: yellow;
  //   opacity: 0.2;
  //   z-index: 1;
  //   width: 100%;
  //   height: 100%;
  // }

  .yidongcenter {
    // background: url("../assets/img/newback.png");
    // background-size: 400% !important;
    // background-position: -410px -100px !important;
  }

  .center {
    background-size: 100%;
    min-height: 100%;
    // padding-bottom: 40px;
    // padding-left: 4%;
    // padding-right: 4%;
    // padding-top: 36px;
    // margin-top: -5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .box-area {
      padding: 2% 4%;
    }
    .box-area:nth-child(2n-1) {
      background-color: #282f46;
    }
    .box {
      .title {
        margin-top: 0px;
        display: flex;
        // justify-content: center;
        font-size: 18px;
        margin-bottom: 0px;
        height: 80px;
        line-height: 80px;
        color: #cedbea;
        position: relative;
        padding-left: 50px;
        font: inherit;
        h3 {
          height: 80px;
          line-height: 80px;
        }
      }

      ul {
        // padding: 0 4%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        li {
          width: 250px;
          position: relative;

          .bei1 {
            // display: flex;
            justify-content: center;
            transition: all 0.2s linear;
            // height: 75.7%;
            img {
              width: 100%;
              // height: 300px;
            }
          }

          .bei2 {
            width: 100%;
            display: flex;
            justify-content: center;
            position: absolute;
            top: 0%;

            img {
              width: 100%;
              // height: 60%;
            }
          }

          .name {
            display: flex;
            justify-content: center;

            .name-warp {
              display: flex;
              align-items: center;
              // color: #c3c3e2;
              color: #ffffff;
              font-size: 18px;

              // font-weight: 600;
              img {
                width: 25px;
                height: 25px;
                margin-right: 10px;
              }
            }
          }

          .money {
            margin-top: 15px;
            display: flex;
            justify-content: center;

            .money-warp {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #fff;

              overflow: hidden;
              border-radius: 20px;
              padding: 6px 16px;
              font-size: 14px;
              width: 70px;
              transition: all 0.2s linear;
              border: 2px transparent solid;

              //boder颜色渐变
              // background-clip: padding-box, border-box;
              // background-origin: padding-box, border-box;
              // background-image: linear-gradient(135deg,rgba(25,30,46,0.8),rgba(255, 255, 255, 0.5)), linear-gradient(135deg, #ff571b, #ff9b0b);
              // background-color:transparent;

              img {
                width: auto;
                height: 20px;
                margin-right: 5px;
              }
            }
            // 红色
            .color\#FF0000 {
              border-color: #ee4946;
              background: linear-gradient(
                to right,
                rgba(238, 73, 70, 0) 0%,
                rgba(238, 73, 70, 0.3) 100%
              );
            }
            // 黄色
            .color\#FFCC33 {
              border-color: #f5c900;
              background: linear-gradient(
                to right,
                rgba(245, 201, 0, 0) 0%,
                rgba(245, 201, 0, 0.3) 100%
              );
            }
            // 灰色
            .color\#000000 {
              border-color: #5e5e5e;
              background: linear-gradient(
                to right,
                rgba(94, 94, 94, 0) 0%,
                rgba(94, 94, 94, 0.3) 100%
              );
            }
            // 蓝色
            .color\#3366FF {
              border-color: #3d5afe;
              background: linear-gradient(
                to right,
                rgba(61, 90, 254, 0) 0%,
                rgba(61, 90, 254, 0.3) 100%
              );
            }
            // 绿色
            .color\#00CC00 {
              border-color: #25a464;
              background: linear-gradient(
                to right,
                rgba(37, 164, 100, 0) 0%,
                rgba(37, 164, 100, 0.3) 100%
              );
            }
            // 紫色
            .color\#9900FF {
              border-color: #883cff;
              background: linear-gradient(
                to right,
                rgba(136, 60, 255, 0) 0%,
                rgba(136, 60, 255, 0.3) 100%
              );
            }
            // 橙色
            .color\#FF5511 {
              border-color: #eca63c;
              background: linear-gradient(
                to right,
                rgba(236, 166, 60, 0) 0%,
                rgba(236, 166, 60, 0.3) 100%
              );
            }
          }
        }
      }

      //动画
      .money-buy {
        display: none;
      }

      .money-kong {
        display: none;
      }

      .box-hover:hover {
        cursor: pointer;
      }
      .box-hover:hover .bei1 {
        transform: scale(1.05);
      }

      .box-hover:hover .money-warp {
        width: 115px;
      }
      .box-hover:hover .money-buy {
        position: absolute;
        // background-color: #FF9B0B;
        background-image: linear-gradient(to right, #ff571b, #ff9b0b);
        border-radius: 20px;
        color: white;
        padding: 7px 16px;
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .box-hover:hover .color\#FF0000 {
        background: #ee4946;
      }
      .box-hover:hover .color\#FFCC33 {
        background: #f5c900;
      }
      .box-hover:hover .color\#000000 {
        background: #5e5e5e;
      }
      .box-hover:hover .color\#3366FF {
        background: #3d5afe;
      }
      .box-hover:hover .color\#00CC00 {
        background: #25a464;
      }
      .box-hover:hover .color\#9900FF {
        background: #883cff;
      }
      .box-hover:hover .color\#FF5511 {
        background: #eca63c;
      }

      .box-hover:hover .money-kong {
        display: block;
        margin-left: 60px;
      }

      .box-hover:hover .bei2 {
        position: absolute;
        top: 20%;
        animation: boxhover 1.5s linear 0s infinite alternate;
      }

      @keyframes boxhover {
        0% {
          top: 20%;
        }

        50% {
          top: 30%;
        }

        100% {
          top: 20%;
        }
      }
    }
  }
}

.center-Content {
  display: flex;
  align-content: center;
  color: hsla(0, 0%, 100%, 0.5);
  margin: 0 -12px;
  justify-content: space-between;

  .first {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
  }

  img {
    width: 25px;
  }
}

.active {
  // width: 40%;
  margin: 20px auto;
  position: relative;

  // display: flex;
  // float: left;
  .active-img {
    width: calc(33.33333% - 5px);
    position: relative;
    margin-left: 2.5px;
    margin-right: 2.5px;
    float: left;

    :hover {
      // transform: translate3d(0,-10px,0);
      // transition: transform 1s;
      animation: imghover 1s infinite;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }

  .img-1 {
    position: absolute;
    cursor: pointer;
  }

  .img-2 {
    visibility: hidden;
  }

  .img-1,
  .img-2 {
    // width: inherit;
    width: 100%;
    height: 90px;
    object-fit: cover;
  }

  @keyframes imghover {
    from {
      top: 0px;
    }

    to {
      top: -15px;
    }
  }
}

#mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-more {
  width: 80%;
  height: auto;
  img {
    border-radius: 8px !important;
  }
}

@media screen and (max-width: 768px) {
  .swiper-more {
    width: 100%;
  }
}
.mask_img {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .active {
    width: 98%;
  }

  .mask_img {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .active-img {
    width: calc(50% - 5px) !important;
  }
}

@media screen and (max-width: 567px) {
  .active-img {
    width: 100% !important;
  }

  .box-area {
    margin-bottom: 10px !important;
  }

  .title {
    // font-size: 14.6px !important;
    // height: 40px !important;
    // margin: 0px 0 !important;
    // background: url("../assets/img/index/title.b1b39dba.png") no-repeat top
    //   center !important;
    // background-size: contain !important;

    span {
      margin-top: -8px !important;
      font-weight: bold;
    }
  }
}
// .index{
// 	background: url("../assets/img/bg-pc.jpg") no-repeat !important;
// 		background-size: 100% 100%;
// 	background-position: center 0;
// 	background-repeat: no-repeat;
// 	background-attachment: fixed;
// 	background-size: cover;
// }
// @media screen and (max-width: 567px) {
// 	background: url("../assets/img/bg-move.jpg") no-repeat !important;
// }

.advertisement {
  width: calc(100% - 395px);
  // width: calc(100% - 0px);
  // height: auto;
  // height: 30px;
  // background-color: black;
  margin-top: 10px;
  margin-left: 197px;
  color: white;
  display: flex;
  align-items: center;
  padding: 0px !important;
  background: linear-gradient(
    90deg,
    rgba(230, 157, 93, 0) 0%,
    rgba(230, 157, 93, 0.4) 18%,
    rgba(230, 157, 93, 0.67) 49%,
    rgba(230, 157, 93, 0.4) 85%,
    rgba(230, 157, 93, 0) 100%
  );
  @media (max-width: 550px) {
    width: 100vw;
    // height: 45px;
    margin-left: 0px;
    box-sizing: border-box;
    // margin-top: -74px;
  }
  .laba {
    width: 30px;
    height: auto;
    // background-color: #38373C;
    // padding-top: 3px;
    padding-left: 2px;
    box-sizing: border-box;
    z-index: 99;
    transform: scale(1.1);
    img {
      width: 20px;
      height: auto;
    }
  }
  .span {
    // height: auto !important;
    width: 100%;
    overflow: hidden;
    margin: 0px !important;
    @media (max-width: 550px) {
      width: 1200px !important;
    }
    .wenzi {
      width: 85%;
      // transform: translateX(0%);
      animation: scrols 15s infinite linear;
      @media (max-width: 550px) {
        width: 1000px !important;
        animation: scrols2 15s infinite linear;
        // font-size: 12px;
      }
      &:hover {
        animation-play-state: paused;
      }
    }
  }
}

@keyframes scrols {
  from {
    transform: translateX(1620px);
  }

  to {
    transform: translateX(-100%);
  }
}
@keyframes scrols2 {
  from {
    transform: translateX(620px);
  }

  to {
    transform: translateX(-100%);
  }
}
.row_1 {
  background-color: #282f46;
}
.main-cese-button {
  height: 72px;
  border-bottom: 1px solid #35394a;
}
.item-box {
  color: #fff;
  // display: flex;
  float: left;
  background: #14142a;
  margin: 12px;
  border-radius: 24px;
  line-height: 36px;
  padding: 6px;
  overflow: hidden;
  font-size: 14px;

  .item {
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    color: #fff;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 120px;
    float: left;
  }
  .case-active {
    background: #3d5afe;
    border-radius: 99px;
  }
}
</style>
<template>
  <!-- <div :class="[showNav ? 'win' : 'winm']"> -->
  <div :class="[showNav ? 'win' : 'winm']">
    <!-- 关闭按钮 -->
    <div class="ng-modal-close" @click="winget"></div>
    <!-- 标题 -->
    <div class="win-header">{{ $t("openBox.WinTitle") }}</div>

    <!-- <div
      :class="{
        'win-con': winList.skins_info.length == 1,
        'win-con1': winList.skins_info.length == 2,
        'win-con2': winList.skins_info.length == 3,
        'win-con3': winList.skins_info.length == 4,
        'win-con4': winList.skins_info.length == 5,
      }"
      v-if="showNav"
    > -->
    <div
      :class="{
        'win-con': winList.skins_info.length == 1,
        'win-con-pc': winList.skins_info.length != 1,
      }"
    >
      <ul class="enter-center">
        <li v-for="(item, index) in winList.skins_info" :key="index">
          <div class="win-warp pc">
            <div
              class="win-text"
              :title="item.name"
              v-if="winList.skins_info.length == 1"
            >
              {{ getShortName(item) }}
            </div>
            <div class="win-texts" v-if="winList.skins_info.length == 1">
              ({{ getExteriorName(item) }})
            </div>
            <div
              class="win-img"
              :style="{
                backgroundImage: 'url(' + getBackZuijin(item) + ')',
              }"
            >
              <div class="border" :class="'color' + item.color"></div>
              <img :src="item.img" />
            </div>
            <!-- 多开信息 -->
            <div class="win-info" v-if="winList.skins_info.length != 1">
              <div class="win-info-left">
                <div class="text">{{ getShortName(item) }}</div>
                <div class="texts">({{ getExteriorName(item) }})</div>
              </div>
              <div class="win-info-right" @click="winexchange(index)">
                <img src="../assets/img/openBox/shopping-cart.svg" alt="" />{{
                  item.price
                }}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="win-bot">
      <div
        class="win-span1"
        @click="winexchange('99')"
        v-if="!isis && !winList.skins_info[0].ccc"
      >
        <img src="../assets/img/openBox/shopping-cart.svg" alt="" />
        <!-- <img src="../assets/img/masonry.png" style="width: 20px" /><span> -->
        {{ total_price }}
        <!-- <span>(分解)</span> -->
      </div>
      <div class="win-span2" @click="winget">
        <img src="../assets/img/openBox/ak47.svg" alt="" />{{
          $t("openBox.Skins")
        }}
      </div>
    </div>
    <!-- <div class="win-bot" v-if="!showNav && winLists != 1">
      <div class="win-span2" @click="winget">放入背包</div>
      <div
        class="win-span1"
        style="margin-left: 12px"
        @click="winexchange('99')"
        v-if="!isis && !winList.skins_info[0].ccc"
      >
        <img src="../assets/img/masonry.png" style="width: 20px" /><span>{{
          total_price
        }}</span>
        <span>(分解)</span>
      </div>
    </div> -->
    <!-- <div class="win-x" @click="winX">X</div> -->
    <!-- <div class="win-back">
			<img src="../assets/img/win3.png" />
		</div> -->
  </div>
</template>

<script>
import Utils from "./../assets/js/util.js";
export default {
  props: ["winList", "winState", "player_skins_ids", "isis"],
  data() {
    return {
      //winState: true,
      list4: [],
      total_price: 0,
      windowWidth: document.body.clientWidth,
      showNav: true,
      winLists: 0,
      language:
        localStorage.getItem("lang") ||
        this.$store.state.currentLanguage ||
        "zh",
    };
  },
  methods: {
    getShortName(item) {
      const lang = this.language;
      switch (lang) {
        case "zh":
          return item.shortName;
        case "en":
          return item.english_shortName;
        default:
          return item.shortName;
      }
    },
    getExteriorName(item) {
      const lang = this.language;
      switch (lang) {
        case "zh":
          return item.exteriorName;
        case "en":
          return item.english_exteriorName;
        default:
          return item.exteriorName;
      }
    },
    extractValuesInParentheses(input) {
      const regex = /\((.*?)\)/g;
      const matches = [];
      let match;

      while ((match = regex.exec(input)) !== null) {
        matches.push(match[1]);
      }

      return matches[0];
    },
    getFrist(e) {
      const resultArray = this.splitAndRemoveParentheses(e);
      return resultArray[0];
    },
    getLast(e) {
      const resultArray = this.splitAndRemoveParentheses(e);
      return resultArray[1];
    },
    splitAndRemoveParentheses(input) {
      const parts = input.split("|");
      const result = parts.map((part) => {
        const indexOfParenthesis = part.indexOf("(");
        if (indexOfParenthesis !== -1) {
          return part.substring(0, indexOfParenthesis).trim();
        }
        return part.trim();
      });
      return result;
    },
    getBackZuijin(e) {
      if (e.color == "#007acc") {
        e.background = require("../assets/img/box-lan.jpg");
      } else if (e.color == "#FF4500") {
        // 红
        e.background = require("../assets/img/box-bai.jpg");
      } else if (e.color == "#8a2be2") {
        // 粉
        e.background = require("../assets/img/box-zi.jpg");
      } else if (e.color == "#FFA500") {
        // 金
        e.background = require("../assets/img/box-jin.jpg");
      }
      return e.background;
    },
    //兑换
    winexchange(index) {
      var param = {
        // player_skins_ids: this.winList.player_skins_ids,
        player_skins_ids: [this.winList.player_skins_ids].join(),
      };
      if (index != "99") {
        param = {
          player_skins_ids: [this.winList.player_skins_ids[index]].join(),
        };
      } else {
        this.$emit("winexchange", false);
      }
      this.$axios
        .post("/api/rucksack/exchangetomoney", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          // console.log(data);
          if (data.code == 1) {
            this.$store.commit("getMoney", data.data.total_amount);
            Utils.$emit("masonry", data.data.total_amount);
            this.$message({
              //message: data.msg,
              message: this.$t('openBoxMessage.success'),
              type: "success",
              customClass: "message-styles",
            });
            if (index != "99") {
              this.winList.skins_info = this.winList.skins_info.filter(
                (item, indexs) => indexs != index
              );
              var a = this.winList.player_skins_ids.splice(
                this.winList.player_skins_ids.indexOf(
                  this.winList.player_skins_ids[index]
                ),
                1
              );
              this.total_price = 0;
              for (let i = 0; i < this.winList.skins_info.length; i++) {
                this.total_price += parseFloat(
                  this.winList.skins_info[i].price
                );
              }
              this.total_price = this.total_price.toFixed(2);
              if (this.winList.player_skins_ids.length == 0) {
                this.$emit("winexchange", false);
              }
            }
          } else {
            this.$message({
              //message: data.msg,
              message: this.$t('openBoxMessage.error'),
              type: "warning",
              customClass: "message-styles"
            });
          }
        });
    },
    //放入背包
    winget() {
      this.$emit("winget", false);
    },
    //x掉
    winX() {
      this.$emit("winX", false);
    },
  },
  mounted() {
    // console.log(this.winList,'---------winList---------')
    this.winLists = this.winList.skins_info.length;
    for (let i = 0; i < this.winList.skins_info.length; i++) {
      this.total_price += Number(this.winList.skins_info[i].price);
    }
    // console.log(this.winList);
    this.total_price = this.total_price.toFixed(2);
    if (this.windowWidth < 1024) {
      this.showNav = false;
    }
  },
};
</script>

<style lang="less" scoped>
.enter-center {
  display: flex;
  justify-content: center;
}
.win_container {
  display: grid !important;
  gap: 5px 10px;
  overflow: auto;
  height: 320px;
  overflow-x: hidden;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)) !important;

  li {
    width: 100%;
  }

  .win-warp {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}

.winm {
  width: 100vw !important;
  height: 100vh !important;
  padding: 0 !important;
  // background-color: #1c2230;
  position: relative;
  box-sizing: border-box;
  border-radius: 10px;
  background: url("../assets/img/openBox/single-bg.jpg") repeat 50% top #1f2539;
  .win-con {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
  .win-con-pc .win-warp {
    width: 90% !important;
    margin-bottom: 20px;
    height: 86px;
    .win-img {
      width: 130px !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 100% !important;
        height: auto !important;
      }
    }
    .win-info {
      font-size: 12px;
      .win-info-left {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
      }
      .win-info-right {
        width: 100px;
        line-height: 30px;
      }
    }
  }

  .win-back {
    width: 100%;
    height: auto;
    position: absolute;
    top: 15%;
    left: 0;
    z-index: -10;
    animation: move 5s linear infinite;

    img {
      width: 100%;
      height: auto;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;

    li {
      width: 100%;
      text-align: center;
      .win-warp {
        width: 100%;
        // height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        position: relative;
        cursor: pointer;

        .top {
          display: flex;
          align-items: center;
        }
        .win-img {
          width: 100%;
          // padding: 20px 0;
          // background-image: url("../assets/img/box-skins-blue.jpg");
          // background-size: 100% 100%;
          // display: flex;
          // align-items: center;
          // justify-content: center;
          // width: 100px;
          img {
            width: 60% !important;
            height: auto;
            // max-height: auto;
            // height: 70px;
            // min-height: 70px;
            margin: 0 auto;
          }
        }

        .win-img1 {
          background-image: url("../assets/img/box-skins-violet.jpg");
        }

        .win-img2 {
          background-image: url("../assets/img/box-skins-golden.jpg");
        }

        .right {
          margin-left: 12px;
        }

        // .win-text {
        //   color: #fff;
        //   font-size: 27px;
        //   font-weight: 700;
        //   padding-bottom: 8px;
        // }

        // .win-texts {
        //   color: #fff;
        //   font-weight: 600;
        //   margin-bottom: 10px;
        // }

        .win-textt {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 95%;
          margin: -10px auto;
          color: white;
          // padding: 10px;
          //overflow: hidden;
          //white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 10px;

          span {
            display: flex;
            align-items: center;
            font-size: 12px !important;
            font-family: "微软雅黑";

            img {
              margin-right: 10px !important;
            }
          }

          span:last-child {
            display: block;
            padding: 6px 24px !important;
            border-radius: 5px;
            background-color: #649907;
          }
        }
      }
    }
  }

  .win-conm {
    margin: auto;
    display: flex;
    justify-content: space-between;
    width: 80% !important;

    li {
      width: 100% !important;
      height: auto;

      .win-warp {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        cursor: pointer;
        height: auto !important;
        background-size: 100% 100%;
        padding: 32px 0;

        .win-img {
          width: 100%;
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          background: transparent;

          img {
            width: 70%;
            height: 100%;
          }
        }

        .win-img1 {
          background-image: url("../assets/img/box-skins-violet.jpg");
        }

        .win-img2 {
          background-image: url("../assets/img/box-skins-golden.jpg");
        }

        .win-text {
          text-overflow: ellipsis;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
          bottom: 30px;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.59);
          margin-top: 10px;
        }

        .win-texts {
          text-align: center;
          color: #80c00c;
        }

        .win-textt {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 6px 0;
          color: white;
          // padding: 10px;
          //overflow: hidden;
          //white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 10px;

          span {
            display: flex;
            align-items: center;
            font-size: 12px !important;
            font-family: "微软雅黑";

            img {
              margin-right: 10px !important;
            }
          }

          span:last-child {
            display: block;
            padding: 6px 24px !important;
            border-radius: 5px;
            background-color: #649907;
          }
        }
      }
    }

    .win-bot2 {
      color: white;
      text-align: center;
      font-family: "微软雅黑";
      display: flex;
      width: 90%;
      margin: 30px auto;

      .win-span1 {
        background-color: hsla(0, 0%, 100%, 0.5);
        border-radius: 4px;
        margin: 0 auto;
        margin-bottom: 0px;
        height: 40px;
        line-height: 40px;
        margin-bottom: 15px;
        font-size: 14px;
        width: 200px;
        text-shadow: 1px 1px 1px #999;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: 15px;
        }

        span:last-child {
          margin-left: 5px;
          color: red;
        }

        img {
          margin-right: 5px;
          width: 15px !important;
          height: 15px;
        }
      }

      .win-span1:hover {
        cursor: pointer;
      }

      .win-span2 {
        background-color: #80c00c;
        border-radius: 4px;
        margin: 0 auto;
        // margin-left: 12px;
        margin-bottom: 0px;
        height: 40px;
        line-height: 40px;
        margin-bottom: 15px;
        font-size: 14px;
        width: 200px;
        text-shadow: 1px 1px 1px #999;
      }

      .win-span2:hover {
        cursor: pointer;
      }
    }
  }

  .win-bot {
    color: white;
    text-align: center;
    font-family: "微软雅黑";
    display: flex;
    width: 90%;
    margin: 30px auto;
    flex-direction: column;
    align-items: center;

    .win-span1 {
      background-color: hsla(0, 0%, 100%, 0.5);
      border-radius: 40px;
      /* margin: 0 auto; */
      margin-bottom: 0px;
      height: 40px;
      line-height: 40px;
      margin-bottom: 15px;
      font-size: 14px;
      width: 60%;
      text-shadow: 1px 1px 1px #999;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #27ae60;
      background: linear-gradient(
        to right,
        rgba(39, 174, 96, 0) 0%,
        rgba(39, 174, 96, 0.3) 100%
      );

      span {
        font-size: 15px;
      }

      span:last-child {
        margin-left: 5px;
        color: red;
      }

      img {
        margin-right: 5px;
        width: 15px !important;
        height: 15px;
      }
    }

    .win-span1:hover {
      cursor: pointer;
    }

    .win-span2 {
      background-color: #80c00c;
      border-radius: 40px;
      /* margin: 0 auto; */
      /* margin-left: 12px; */
      margin-bottom: 0px;
      height: 40px;
      line-height: 40px;
      /* margin-bottom: 15px; */
      font-size: 14px;
      width: 60%;
      text-shadow: 1px 1px 1px #999;
      display: flex;
      justify-content: center;
      border: 2px solid #3d5afe;
      background: linear-gradient(
        to right,
        rgba(61, 90, 254, 0) 0%,
        rgba(61, 90, 254, 0.3) 100%
      );
    }

    .win-span2:hover {
      cursor: pointer;
    }
  }
}

.win {
  // max-width: 1440px;
  width: 35%;
  padding: 20px;
  // background-color: #1c2230;
  position: absolute;
  box-sizing: border-box;
  // border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("../assets/img/openBox/single-bg.jpg") repeat 50% top #1f2539;
  background-size: cover;
  text-align: center;
  border: none;
  box-shadow: 4px 4px 80px #000;
  // padding-top: 0;
  .win-back {
    width: 100%;
    height: auto;
    position: absolute;
    top: 15%;
    left: 0;
    z-index: -10;
    animation: move 5s linear infinite;

    img {
      width: 100%;
      height: auto;
    }
  }

  ul {
    margin: auto;
    display: flex;
    justify-content: space-between;

    li {
      width: 240px;
      // margin-left: 50px;
      margin-bottom: 10px;
      .win-warp {
        // width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        cursor: pointer;

        .win-img {
          // padding: 30px 0;
          background-image: url("../assets/img/box-skins-blue.jpg");
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: auto;
            height: auto;
            // max-height: 150px;
            height: 150px;
            // min-height: 150px;
          }
        }

        .win-img1 {
          background-image: url("../assets/img/box-skins-violet.jpg");
        }

        .win-img2 {
          background-image: url("../assets/img/box-skins-golden.jpg");
        }

        .win-textt {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 6px 0;
          color: white;
          // padding: 10px;
          //overflow: hidden;
          //white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 10px;

          span {
            display: flex;
            align-items: center;
            font-size: 12px !important;
            font-family: "微软雅黑";

            img {
              margin-right: 10px !important;
            }
          }

          span:last-child {
            display: block;
            padding: 6px 24px !important;
            border-radius: 5px;
            background-color: #649907;
          }
        }
      }
    }
  }

  @keyframes move {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .win-top {
    width: 100%;

    display: flex;
    justify-content: center;

    img {
      width: 50%;
      // height: 200px;
    }
  }

  .win-x {
    position: absolute;
    top: -20px;
    right: 20px;
    color: #e9b10e;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #e9b10e;
    text-align: center;
    line-height: 20px;
  }

  .win-x:hover {
    cursor: pointer;
  }

  .win-bot {
    margin-top: 30px;
    color: white;
    text-align: center;
    font-family: "微软雅黑";
    display: flex;
    .win-span1 {
      border-radius: 40px;
      margin: 0 auto;
      margin-bottom: 0px;
      height: 40px;
      line-height: 40px;
      margin-bottom: 15px;
      font-size: 14px;
      width: 200px;
      text-shadow: 1px 1px 1px #999;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      border: 2px solid #27ae60;
      background: linear-gradient(
        to right,
        rgba(39, 174, 96, 0) 0%,
        rgba(39, 174, 96, 0.3) 100%
      );
      span {
        font-size: 15px;
      }

      span:last-child {
        margin-left: 5px;
        color: red;
      }

      img {
        margin-right: 5px;
        width: 20px !important;
        height: 20px;
      }
    }

    .win-span1:hover {
      cursor: pointer;
      background: #27ae60;
    }

    .win-span2 {
      border-radius: 40px;
      margin: 0 auto;
      margin-bottom: 0px;
      height: 40px;
      line-height: 40px;
      margin-bottom: 15px;
      font-size: 14px;
      width: 200px;
      text-shadow: 1px 1px 1px #999;
      border: 2px solid #3d5afe;
      background: linear-gradient(
        to right,
        rgba(61, 90, 254, 0) 0%,
        rgba(61, 90, 254, 0.3) 100%
      );
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 5px;
        width: 20px !important;
        height: 20px;
      }
    }

    .win-span2:hover {
      cursor: pointer;
      background: #3d5afe;
    }
  }

  .win-con {
    width: 100%;
    display: flex;
    justify-content: center;

    ul {
      width: 100%;
      display: flex;
      justify-content: center;

      li {
        width: 100%;
        border-radius: 5px;
        // overflow: hidden;

        .win-warp {
          width: 100%;
          // background-color: #e2c873;
          position: relative;
          cursor: pointer;
          display: flex;
          justify-content: center;
          // margin-left: 13px;
          // margin-left: -110px;
          .win-img {
            // background-size: 100% 100%;
            // width: 478px;
            // height: 206px;
            // background-image: url("../assets/img/box-lan.jpg");
            // background-size: 100% 100%;

            // img {
            //   width: 100% !important;
            //   height: auto !important;
            // }
          }

          .win-img1 {
            background-image: url("../assets/img/box-skins-violet.jpg");
          }

          .win-img2 {
            background-image: url("../assets/img/box-skins-golden.jpg");
          }

          .win-text {
            // text-overflow: ellipsis;
            // position: absolute;
            // width: 100px;
            // text-align: center;
            // left: 0;
            // right: 0;
            // margin-left: auto;
            // margin-right: auto;
            // bottom: 30px;
            // font-size: 10px;
            // color: white;
          }

          .win-textt {
            display: none;
            width: 100%;
            padding: 6px 0;
            background-color: #e9b10e;
            background-size: 100% 100%;
            border: 1px solid #ffea95;
            color: #000000;
            // padding: 10px;
            //overflow: hidden;
            //white-space: nowrap;
            text-overflow: ellipsis;
            color: #000;
            font-size: 10px;
          }
        }
      }
    }
  }

  //   .win-con1 {
  //   	display: flex;
  //   	justify-content: center;

  //   	ul {
  //   		margin: -5px;
  //   		width: 80%;
  //   		display: flex;
  //   		flex-flow: row wrap;

  //   		//flex-wrap: wrap;
  //   		li {
  //   			width: 50%;
  //   			border-radius: 5px;
  //   			margin: 5px 0;

  //   			.win-warp {
  //   				width: 100%;
  //   				position: relative;
  //   				cursor: pointer;

  //   				.win-img {
  //   					padding: 10px;
  //   					background-image: url("../assets/img/box-skins-blue.jpg");
  //   					background-size: 100% 100%;

  //   					img {
  //   						width: 100%;
  //   						height: auto;
  //   					}
  //   				}

  //   				.win-img1 {
  //   					background-image: url("../assets/img/box-skins-violet.jpg");
  //   				}

  //   				.win-img2 {
  //   					background-image: url("../assets/img/box-skins-golden.jpg");
  //   				}

  //   				.win-text {
  //   					text-overflow: ellipsis;
  //   					position: absolute;
  //   					width: 100px;
  //   					text-align: center;
  //   					left: 0;
  //   					right: 0;
  //   					margin-left: auto;
  //   					margin-right: auto;
  //   					bottom: 30px;
  //   					font-size: 10px;
  //   					color: white;
  //   				}

  //   				.win-textt {
  //   					width: 100%;
  //   					padding: 6px 0;
  //   					background-color: #e9b10e;
  //   					background-size: 100% 100%;
  //   					border: 1px solid #ffea95;
  //   					color: #000000;
  //   					// padding: 10px;
  //   					//overflow: hidden;
  //   					//white-space: nowrap;
  //   					text-overflow: ellipsis;
  //   					font-size: 10px;
  //   				}
  //   			}
  //   		}
  //   	}
  //   }

  //   .win-con2 {
  //   	display: flex;
  //   	justify-content: center;

  //   	ul {
  //   		margin: -5px;
  //   		width: 80%;
  //   		display: flex;
  // 		justify-content: center;
  //   		// flex-flow: row wrap;

  //   		li {
  //   			margin-top: 5px;
  //   			overflow: hidden;
  //   			width: 50%;
  //   			width: 50%;
  //   			border-radius: 5px;
  //   			// background-color: #e2c873;
  //   			margin: 5px 0;

  //   			.win-warp {
  //   				border-radius: 5px;
  //   				overflow: hidden;
  //   				width: 100%;
  // 				height: auto;
  //   				cursor: pointer;
  //   				position: relative;

  //   				.win-img {
  // 					width: 100% !important;
  // 					height: auto;
  //   					// padding: 10px;
  //   					background-image: url("../assets/img/box-skins-blue.jpg");
  //   					background-size: 100% 100%;

  //   					img {
  //   						border-top-right-radius: 5px;
  //   						width: 100%;
  //   						height: auto;
  //   					}
  //   				}

  //   				.win-img1 {
  //   					background-image: url("../assets/img/box-skins-violet.jpg");
  //   				}

  //   				.win-img2 {
  //   					background-image: url("../assets/img/box-skins-golden.jpg");
  //   				}

  //   				.win-text {
  //   					text-overflow: ellipsis;
  //   					position: absolute;
  //   					width: 100px;
  //   					text-align: center;
  //   					left: 0;
  //   					right: 0;
  //   					margin-left: auto;
  //   					margin-right: auto;
  //   					bottom: 30px;
  //   					font-size: 10px;
  //   					color: white;
  //   				}

  //   				.win-textt {
  //   					width: 100%;
  //   					padding: 6px 0;
  //   					background-color: #e9b10e;
  //   					background-size: 100% 100%;
  //   					border: 1px solid #ffea95;
  //   					color: #000000;
  //   					// padding: 10px;
  //   					//overflow: hidden;
  //   					//white-space: nowrap;
  //   					text-overflow: ellipsis;
  //   					font-size: 10px;
  //   				}
  //   			}
  //   		}
  //   	}
  //   }

  // .win-con3 {
  // 	display: flex;
  // 	justify-content: center;

  // 	ul {
  // 		margin: -5px;
  // 		width: 80%;
  // 		display: flex;
  // 		flex-flow: row wrap;

  // 		li {
  // 			// margin: 5px;
  // 			min-width: 50%;
  // 			max-width: 50%;
  // 			margin: 5px 0;

  // 			.win-warp {
  // 				border-radius: 5px;
  // 				overflow: hidden;
  // 				// width: 100%;
  // 				cursor: pointer;
  // 				position: relative;

  // 				.win-img {
  // 					padding: 10px;
  // 					background-image: url("../assets/img/box-skins-blue.jpg");
  // 					background-size: 100% 100%;

  // 					img {
  // 						width: 100%;
  // 						height: auto;
  // 					}
  // 				}

  // 				.win-img1 {
  // 					background-image: url("../assets/img/box-skins-violet.jpg");
  // 				}

  // 				.win-img2 {
  // 					background-image: url("../assets/img/box-skins-golden.jpg");
  // 				}

  // 				.win-text {
  // 					text-overflow: ellipsis;
  // 					position: absolute;
  // 					width: 100px;
  // 					text-align: center;
  // 					left: 0;
  // 					right: 0;
  // 					margin-left: auto;
  // 					margin-right: auto;
  // 					bottom: 30px;
  // 					font-size: 10px;
  // 					color: white;
  // 				}

  // 				.win-textt {
  // 					width: 100%;
  // 					padding: 6px 0;
  // 					background-color: #e9b10e;
  // 					background-size: 100% 100%;
  // 					border: 1px solid #ffea95;
  // 					color: #000000;
  // 					// padding: 10px;
  // 					//overflow: hidden;
  // 					//white-space: nowrap;
  // 					text-overflow: ellipsis;
  // 					font-size: 10px;
  // 				}
  // 			}
  // 		}
  // 	}
  // }

  // .win-con4 {
  // 	display: flex;
  // 	justify-content: center;

  // 	ul {
  // 		margin: -5px;
  // 		width: 80%;
  // 		display: flex;
  // 		flex-flow: row wrap;

  // 		li {
  // 			// margin: 5px;
  // 			margin: 5px 0;
  // 			min-width: 50%;
  // 			max-width: 50%;

  // 			.win-warp {
  // 				border-radius: 5px;
  // 				overflow: hidden;
  // 				// width: 100%;
  // 				position: relative;
  // 				cursor: pointer;

  // 				.win-img {
  // 					padding: 10px;
  // 					background-image: url("../assets/img/box-skins-blue.jpg");
  // 					background-size: 100% 100%;

  // 					img {
  // 						width: 100%;
  // 						height: auto;
  // 					}
  // 				}

  // 				.win-img1 {
  // 					background-image: url("../assets/img/box-skins-violet.jpg");
  // 				}

  // 				.win-img2 {
  // 					background-image: url("../assets/img/box-skins-golden.jpg");
  // 				}

  // 				.win-text {
  // 					text-overflow: ellipsis;
  // 					position: absolute;
  // 					width: 100px;
  // 					text-align: center;
  // 					left: 0;
  // 					right: 0;
  // 					margin-left: auto;
  // 					margin-right: auto;
  // 					bottom: 30px;
  // 					font-size: 10px;
  // 					color: white;
  // 				}

  // 				.win-textt {
  // 					width: 100%;
  // 					padding: 6px 0;
  // 					// padding: 10px;
  // 					//overflow: hidden;
  // 					//white-space: nowrap;
  // 					text-overflow: ellipsis;
  // 					background-color: #e9b10e;
  // 					background-size: 100% 100%;
  // 					border: 1px solid #ffea95;
  // 					color: #000000;

  // 					font-size: 10px;
  // 				}
  // 			}
  // 		}

  // 		li:nth-child(1) {
  // 			min-width: 45%;
  // 			max-width: 45%;
  // 			margin-left: 25%;
  // 		}

  // 		li:nth-child(2) {
  // 			min-width: 50%;
  // 			max-width: 50%;
  // 		}
  // 	}
  // }
}

// .win-conm {
// 	display: flex;
// 	justify-content: center;
// 	flex-direction: column;
// 	align-items: center;

// 	.win-tips {
// 		height: 60px;
// 		color: #fff;
// 		font-size: 16px;
// 		line-height: 60px;
// 		font-weight: bold;
// 	}

// 	ul {
// 		margin: -5px;
// 		width: 100%;
// 		display: flex;
// 		flex-flow: row wrap;
// 		justify-content: center;

// 		li {
// 			.win-warp {
// 				border-radius: 5px;
// 				overflow: hidden;
// 				// width: 100%;
// 				cursor: pointer;
// 				position: relative;

// 				.win-img {
// 					padding: 10px;
// 					background-image: url("../assets/img/box-skins-blue.jpg");
// 					background-size: 100% 100%;

// 					img {
// 						width: 100%;
// 						height: auto;
// 					}
// 				}

// 				.win-img1 {
// 					background-image: url("../assets/img/box-skins-violet.jpg");
// 				}

// 				.win-img2 {
// 					background-image: url("../assets/img/box-skins-golden.jpg");
// 				}

// 				.win-text {
// 					text-overflow: ellipsis;
// 					position: absolute;
// 					width: 100%;
// 					text-align: center;
// 					left: 0;
// 					right: 0;
// 					bottom: 0;
// 					margin-left: auto;
// 					margin-right: auto;
// 					bottom: 30px;
// 					font-size: 8px !important;
// 					color: white;
// 				}

// 				.win-textt {
// 					width: 100%;
// 					padding: 6px 0;
// 					background-color: #e9b10e;
// 					background-size: 100% 100%;
// 					border: 1px solid #ffea95;
// 					color: #000000;
// 					// padding: 10px;
// 					//overflow: hidden;
// 					//white-space: nowrap;
// 					text-overflow: ellipsis;
// 					font-size: 10px;
// 				}
// 			}
// 		}
// 	}
// }

// .win-con1m {
// 	display: flex;
// 	justify-content: center;
// 	flex-direction: column;
// 	align-items: center;

// 	.win-tips {
// 		height: 60px;
// 		color: #fff;
// 		font-size: 16px;
// 		line-height: 60px;
// 		font-weight: bold;
// 	}

// 	ul {
// 		margin: -5px;
// 		width: 100%;
// 		display: flex;
// 		flex-flow: row wrap;
// 		justify-content: center;

// 		li {
// 			.win-warp {
// 				border-radius: 5px;
// 				overflow: hidden;
// 				// width: 100%;
// 				cursor: pointer;
// 				position: relative;

// 				.win-img {
// 					padding: 10px;
// 					background-image: url("../assets/img/box-skins-blue.jpg");
// 					background-size: 100% 100%;

// 					img {
// 						width: 100%;
// 						height: auto;
// 					}
// 				}

// 				.win-img1 {
// 					background-image: url("../assets/img/box-skins-violet.jpg");
// 				}

// 				.win-img2 {
// 					background-image: url("../assets/img/box-skins-golden.jpg");
// 				}

// 				.win-text {
// 					text-overflow: ellipsis;
// 					position: absolute;
// 					width: 100px;
// 					text-align: center;
// 					left: 0;
// 					right: 0;
// 					margin-left: auto;
// 					margin-right: auto;
// 					bottom: 30px;
// 					font-size: 8px !important;
// 					color: white;
// 				}

// 				.win-textt {
// 					width: 100%;
// 					padding: 6px 0;
// 					background-color: #e9b10e;
// 					background-size: 100% 100%;
// 					border: 1px solid #ffea95;
// 					color: #000000;
// 					// padding: 10px;
// 					//overflow: hidden;
// 					//white-space: nowrap;
// 					text-overflow: ellipsis;
// 					font-size: 10px;
// 				}
// 			}
// 		}
// 	}
// }

// .win-con2m {
// 	display: flex;
// 	justify-content: center;
// 	flex-direction: column;
// 	align-items: center;

// 	.win-tips {
// 		height: 60px;
// 		color: #fff;
// 		font-size: 16px;
// 		line-height: 60px;
// 		font-weight: bold;
// 	}

// 	ul {
// 		margin: -5px;
// 		width: 100%;
// 		display: flex;
// 		flex-flow: row wrap;
// 		justify-content: center;

// 		li {
// 			.win-warp {
// 				border-radius: 5px;
// 				overflow: hidden;
// 				// width: 100%;
// 				position: relative;
// 				cursor: pointer;

// 				.win-img {
// 					padding: 10px;
// 					background-image: url("../assets/img/box-skins-blue.jpg");
// 					background-size: 100% 100%;

// 					img {
// 						width: 100%;
// 						height: auto;
// 					}
// 				}

// 				.win-img1 {
// 					background-image: url("../assets/img/box-skins-violet.jpg");
// 				}

// 				.win-img2 {
// 					background-image: url("../assets/img/box-skins-golden.jpg");
// 				}

// 				.win-text {
// 					text-overflow: ellipsis;
// 					position: absolute;
// 					width: 100px;
// 					text-align: center;
// 					left: 0;
// 					right: 0;
// 					margin-left: auto;
// 					margin-right: auto;
// 					bottom: 30px;
// 					font-size: 8px !important;
// 					color: white;
// 				}

// 				.win-textt {
// 					width: 100%;
// 					padding: 6px 0;
// 					background-color: #e9b10e;
// 					background-size: 100% 100%;
// 					border: 1px solid #ffea95;
// 					color: #000000;
// 					// padding: 10px;
// 					//overflow: hidden;
// 					//white-space: nowrap;
// 					text-overflow: ellipsis;
// 					font-size: 10px;
// 				}
// 			}
// 		}
// 	}
// }

// .win-con3m {
// 	display: flex;
// 	justify-content: center;
// 	flex-direction: column;
// 	align-items: center;

// 	.win-tips {
// 		height: 60px;
// 		color: #fff;
// 		font-size: 16px;
// 		line-height: 60px;
// 		font-weight: bold;
// 	}

// 	ul {
// 		margin: -5px;
// 		width: 100%;
// 		display: flex;
// 		flex-flow: row wrap;
// 		justify-content: center;

// 		li {
// 			margin: 5px 0;

// 			.win-warp {
// 				border-radius: 5px;
// 				overflow: hidden;
// 				// width: 100%;
// 				position: relative;
// 				cursor: pointer;

// 				.win-img {
// 					padding: 10px;
// 					background-image: url("../assets/img/box-skins-blue.jpg");
// 					background-size: 100% 100%;

// 					img {
// 						width: 100%;
// 						height: auto;
// 					}
// 				}

// 				.win-img1 {
// 					background-image: url("../assets/img/box-skins-violet.jpg");
// 				}

// 				.win-img2 {
// 					background-image: url("../assets/img/box-skins-golden.jpg");
// 				}

// 				.win-text {
// 					text-overflow: ellipsis;
// 					position: absolute;
// 					width: 100px;
// 					text-align: center;
// 					left: 0;
// 					right: 0;
// 					margin-left: auto;
// 					margin-right: auto;
// 					bottom: 30px;
// 					font-size: 8px !important;
// 					color: white;
// 				}

// 				.win-textt {
// 					width: 100%;
// 					padding: 6px 0;
// 					background-color: #e9b10e;
// 					background-size: 100% 100%;
// 					border: 1px solid #ffea95;
// 					color: #000000;
// 					//overflow: hidden;
// 					//white-space: nowrap;
// 					text-overflow: ellipsis;
// 					font-size: 10px;
// 				}
// 			}
// 		}
// 	}
// }

// .win-con4m {
// 	display: flex;
// 	justify-content: center;
// 	flex-direction: column;
// 	align-items: center;

// 	.win-tips {
// 		height: 60px;
// 		color: #fff;
// 		font-size: 16px;
// 		line-height: 60px;
// 		font-weight: bold;
// 	}

// 	ul {
// 		margin: -5px;
// 		width: 100%;
// 		display: flex;
// 		flex-flow: row wrap;
// 		justify-content: center;

// 		li {

// 			// margin: 5px;
// 			.win-warp {
// 				border-radius: 5px;
// 				overflow: hidden;
// 				// width: 100%;
// 				cursor: pointer;
// 				position: relative;

// 				.win-img {
// 					padding: 10px;
// 					background-image: url("../assets/img/box-skins-blue.jpg");
// 					background-size: 100% 100%;

// 					img {
// 						width: 100%;
// 						height: auto;
// 					}
// 				}

// 				.win-img1 {
// 					background-image: url("../assets/img/box-skins-violet.jpg");
// 				}

// 				.win-img2 {
// 					background-image: url("../assets/img/box-skins-golden.jpg");
// 				}

// 				.win-text {
// 					text-overflow: ellipsis;
// 					position: absolute;
// 					text-align: center;
// 					left: 0px;
// 					right: 0;
// 					margin-left: auto;
// 					margin-right: auto;
// 					bottom: 30px;
// 					font-size: 8px !important;
// 					color: white;
// 					transform: scale(0.8);
// 				}

// 				.win-textt {
// 					width: 100%;
// 					padding: 6px 0;
// 					// padding: 10px;
// 					//overflow: hidden;
// 					//white-space: nowrap;
// 					text-overflow: ellipsis;
// 					background-color: #e9b10e;
// 					background-size: 100% 100%;
// 					border: 1px solid #ffea95;
// 					color: #000000;
// 					font-size: 10px;
// 				}
// 			}
// 		}
// 	}
// }

@media screen and (max-width: 767px) {
  .kai-box {
    padding: 0 !important;
  }

  .win-box {
    left: 0 !important;
    margin-left: 0 !important;
  }

  .win-box {
    z-index: 99999999999;

    // .win {
    // 	width: 85vw !important;
    // 	left: 0vw;
    // 	top: 0vh;
    // 	// transform: translateX(-50%);
    // 	border-radius: 10px;
    // .win-img {
    //   img {
    //     width: 80% !important;
    //     height: auto !important;
    //   }
    // }
    // .win-text {
    //   // font-size: 12px;
    //   // overflow: hidden;
    //   // text-overflow: ellipsis;
    //   // white-space: nowrap;
    //   // color: white;
    //   margin-top: 60px;
    //   // margin-bottom: 10p?x ;
    //   font-size: 24px !important;
    // }
    // }
  }
}
.pc {
  display: flex;
  width: 100% !important;
  justify-content: center;
  align-items: center;
  .win-img {
    width: 478px;
    height: 206px;
    background-size: 100% 100%;
    position: relative;
  }
  img {
    height: 206px;
    margin-left: 0 !important;
    filter: brightness(1.1) saturate(1.15);
  }
}
.ng-modal-close {
  position: absolute;
  top: 3px;
  right: 5px;
  padding: 5px;
  cursor: pointer;
  font-size: 120%;
  display: inline-block;
  font-weight: 700;
  font-family: Roboto, "sans-serif";
  background: url("../assets/img/openBox/close.svg") no-repeat 50% 50%;
  padding: 32px;
  opacity: 0.8;
}
.win-header {
  color: #2fcf72;
  font-weight: 700;
  line-height: 30px;
  padding: 30px 0 0;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  height: 30px;
  margin-bottom: 20px;
}
.border {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0.375rem;
  z-index: 99;
} // 蓝色
.color\#007acc {
  background-image: linear-gradient(to bottom, #4b69ff 0, #2740bb 100%);
}
// 紫色
.color\#8a2be2 {
  background-image: linear-gradient(to bottom, #8847ff 0, #5e29bf 100%);
}
// 金色
.color\#FFA500 {
  background-image: linear-gradient(to bottom, #ffae39 0%, #b46d06 100%);
}
// 红色
.color\#FF4500 {
  background-image: linear-gradient(to bottom, #e34343 0%, #ca2d2d 100%);
}
.win-con-pc {
  width: 100%;
  .enter-center {
    display: flex;
    flex-direction: column;
    .win-img {
      width: 86px;
      height: 100%;
      img {
        height: 61px !important;
      }
    }
  }
  .win-warp {
    display: flex !important;
    justify-content: flex-start !important;
    flex-direction: row !important;
    width: 80% !important;
    margin: 0 auto;
    height: 70px;
  }
  .win-info {
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 396px;
    background: #131724;
    // height: 100%;
    box-sizing: border-box;
    padding: 0px 20px;
    color: #a5aabd;
    // line-height: 23px;
    height: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    .win-info-right {
      border-radius: 40px;

      height: 40px;
      font-size: 14px;
      width: 120px;
      text-shadow: 1px 1px 1px #999;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #27ae60;
      background: linear-gradient(
        to right,
        rgba(39, 174, 96, 0) 0%,
        rgba(39, 174, 96, 0.3) 100%
      );
      img {
        width: 20px;
        height: 20px;
        margin-right: 2px;
      }
    }
    .win-info-left {
      color: #fff;
      .text {
        font-weight: 700;
      }
      .texts {
        color: #d2d3d5;
        font-size: 12px;
      }
    }
  }
}
.win-con-pc li {
  width: 100% !important;
  margin-left: 0 !important;
  height: 100%;
}
.win-text {
  color: #fff;
  font-size: 27px;
  font-weight: 700;
  padding-bottom: 8px;
}

.win-texts {
  text-align: center;
  color: #9ba0b5;
  margin-bottom: 30px;
}
</style>

<template>
  <div class="panel">
    <div class="user-panel--content">
      <div class="openbox-container_header breadcrumb">
        <!-- 返回首页 -->
        <div class="cat-breadcrumb" @click="$router.push('/')">csgoskins</div>
        <div class="ng-binding">
          <img src="../assets/img/chevron-down-1.svg" alt="" />
          {{ Data.name }}
        </div>
      </div>
      <!-- 个人信息 -->
      <div class="user-info">
        <div class="left">
          <!-- 头像 -->
          <img class="avatar" :src="Data.image" alt="" />
          <!-- 信息 -->
          <div class="info">
            <div class="name">{{ Data.name }}</div>
            <div class="steam-id">
              SteamID64: {{ Data.steamId }}
              <span
                class="tippy copy-span"
                @click="copyText(Data.steamId)"
              ></span>
            </div>
            <div class="steam-profile" v-if="showNav">
              <div class="steam-profile-url">
                <a :href="`https://steamcommunity.com/profiles/${Data.steamId}`"
                  >{{ $t('profile.SteamProfile') }}</a
                >
              </div>
              <!-- 下拉菜单 -->
              <!-- <div class="selection"></div> -->
              <el-tooltip
                class="item tippy"
                effect="dark"
                content="Select country that you currently live in to see SMS payments. "
                placement="top"
              >
                <div>?</div>
              </el-tooltip>
            </div>
            <div class="blance" v-if="showNav">
              <!-- <img
                class="wallet"
                src="../assets/img/profile/wallet-ico.png"
                alt=""
              /> -->
              {{ $t('profile.Balance') }}:
              <span class="panel-wallet-money">{{ Data.total_amount }} </span>
              <div @click="openWallet()" class="open-wallet">+</div>
            </div>
          </div>
        </div>
        <div class="catalyst__action" v-if="!showNav">
          <div class="catalyst__action--steam">
            <a
              :href="`https://steamcommunity.com/profiles/${Data.steamId}`"
              target="_blank"
              class="ng-binding"
            >
              STEAM PROFILE
            </a>
          </div>

          <div class="catalyst__action--help tippy">
            <el-tooltip
              class="item"
              effect="dark"
              content="Selectcountry thatyou currentlyliveintoseeSMS
payments."
              placement="top"
            >
              <div>?</div>
            </el-tooltip>
          </div>
        </div>
        <div class="catalyst__balance" v-if="!showNav">
          <div class="catalyst__balance--label ng-binding"> {{ $t('profile.Balance') }}:</div>
          <div class="catalyst__balance--value">
            <span class="ng-binding">{{ Data.total_amount }}</span>
            <a @click="openWallet()" class="button plus-button">+</a>
          </div>
        </div>
        <div class="right">
          <div class="user-counters">
            <div class="counter-cases">
              <div class="sep">
                <h4 class="font-big ng-binding"> {{ $t('profile.OpenNum') }}</h4>
                <div class="number ng-binding">{{ Data.box_open_count }}</div>
              </div>
            </div>

            <div class="counter-upgrades">
              <div class="sep">
                <h4 class="font-big ng-binding">{{ $t('profile.UpgradeNum') }}</h4>
                <div class="number ng-binding">{{ Data.upgrader_count }}</div>
              </div>
            </div>
          </div>
          <label class="ng-binding">
            {{ $t('profile.SteamURL') }}
            <a
              href="https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url"
              target="_blank"
              class="tradeurl-help ng-binding"
            >{{ $t('profile.WhereIsMyURL') }}
              
            </a>
          </label>
          <div class="form-tradeurl">
            <el-input
              id="tradeurl_input"
              v-model="steamUrl"
              placeholder="Your Steam Trade URL"
            ></el-input>
            <div class="Save" @click="bindSteam">{{ $t('profile.Save') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="user-panel--content skins-box">
      <div class="tabs panel-tabs">
        <div>
          <a
            class="ng-binding tab"
            :class="{ selected: currentType == 0 }"
            @click="currentType = 0"
          >{{ $t('profile.Skins')}}
          </a>
          <a
            class="ng-binding ng-scope tab"
            :class="{ selected: currentType == 1 }"
            @click="currentType = 1"
          >{{ $t('profile.UPGRADES')}}
             </a
          ><a
            class="ng-binding ng-scope tab"
            :class="{ selected: currentType == 2 }"
            @click="currentType = 2"
          >{{ $t('profile.CASES')}}
           
          </a>
        </div>
      </div>
      <div class="box-list" v-if="currentType == 0">
        <div class="xiangzi">
          <ul>
            <li v-for="(item, index) in skinList" :key="index" class="card">
              <div
                class="border"
                :class="'gradient-color-' + item.type_color"
              ></div>
              <div class="boxlist-warp">
                <div class="list-pirce" >
                  <div
                    class="pirce-right"
                    @click="getPull($event, item.id, item.itemId)"
                  >
                    <span>{{$t("profile.send")}}</span>
                  </div>
                  <div class="pirce-left" @click="getExchange($event, item.id)">
                    <span class="shopping-cart"></span>
                    <span class="shopping-price">{{ item.price }}</span>
                    <!-- <div>
                    </div> -->
                  </div>
                </div>
                <div class="item-background ng-scope" v-if="!showNav">
                  <div class="item-main-background"></div>
                  <div class="item-background-elements">
                    <div class="item-image-background"></div>
                    <div
                      class="item_image_background_1"
                      :class="'item_image_background_1_' + item.color"
                    ></div>
                    <div
                      class="item_image_background_2"
                      :class="'item_image_background_2_' + item.color"
                    ></div>
                  </div>
                </div>
                <div class="boxlist1-top">
                  <img :src="item.img" class="item-image-filter" />
                </div>
                <div class="boxlist1-bottom">
                  <div class="boxlist1-bot" :title="item.name">
                    {{ getShortName(item) }}
                  </div>
                  <div class="boxlist1-bot2" :title="item.name">
                    <span>{{ getExteriorName(item) }}</span>
                  </div>
                </div>
                <div class="upGrade">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.49319 0.986572H11.031V8.54213"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M11.031 0.986572L0.96875 11.0488"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                  <div class="ng-binding">{{ $t("profile.Upgrade") }}</div>
                </div>
              </div>
            </li>
            <li class="card"></li>
            <li class="card"></li>
            <li class="card"></li>
            <li class="card"></li>
            <li class="card"></li>
            <li class="card"></li>
            <li class="card"></li>
          </ul>
        </div>
        <div class="clear"></div>
      </div>
      <div class="tab-content">
        <div>
          <!-- <div class="empty ng-binding ng-scope">
            You don't have any skins yet
          </div> -->

          <!-- <div class="pager">
            <a
              data-ng-show="vm.items.links.prev"
              data-ng-click="vm.prevPage()"
              class="button button-case ng-hide"
            >
              <span data-ng-hide="vm.prevLoading" class="ng-binding">Back</span>
              <img
                src="/images/spinner.svg"
                class="spinner ng-hide"
                data-ng-show="vm.prevLoading"
              />
            </a>
            <a
              data-ng-show="vm.items.links.next"
              data-ng-click="vm.nextPage()"
              class="button button-case ng-hide"
            >
              <span data-ng-hide="vm.nextLoading" class="ng-binding">Next</span>
              <img
                src="/images/spinner.svg"
                class="spinner ng-hide"
                data-ng-show="vm.nextLoading"
              />
            </a>
          </div> -->
        </div>
      </div>
    </div>
    <myhomebot></myhomebot>
  </div>
</template>
<script>
import Utils from "../assets/js/util.js";
import myhomebot from "@/components/my_homebot.vue";
export default {
  components: {
    myhomebot,
  },
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      Data: {},
      steamUrl: "",
      tradingCode: null,
      flag: false,
      site: "",
      time: "",
      inviteImg: "",
      inviteName: "",
      page: 1,
      pageSize: 10,
      total: 0, //总条数
      total_value: 0, //总价值
      skinList: [],
      language:
        localStorage.getItem("lang") ||
        this.$store.state.currentLanguage ||
        "zh",
      random: "",
      checkedPrice: 0,
      exchangeType: "diamond",
      changeDecoration: [],
      currentType: 0,
    };
  },
  methods: {
    //个人中心信息
    getList() {
      let param = {};
      this.$axios
        .post("/api/player/playerdata", this.$qs.stringify(param))
        .then((res) => {
          console.log(res.data);
          var data = res.data;
          if (data.code == "1") {
            this.Data = data.data.player;
            console.log(this.Data);
            this.steamUrl = this.Data.tradeUrl;
            if (this.steamUrl) {
              this.flag = true;
            }
            this.site = this.Data.last_login_info.position;
            this.time = this.Data.last_login_info.time;
            if (data.data.myInviter) {
              this.inviteImg = data.data.myInviter.img;
              this.inviteName = data.data.myInviter.name;
            }
          }
        });
    },
    // 绑定steam
    bindSteam() {
      let param = {
        tradeUrl: this.steamUrl,
        code: this.tradingCode,
      };
      this.$axios
        .post("/api/player/bindsteam", this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.code == "1") {
            this.$message({
              showClose: true,
              message: this.$t('profileMessage.SteamSuccess'),
              type: "success",
              customClass: "message-styles"
            });
            this.$parent.getList();
            this.chushihua();
            this.flag = true;
          } else {
            this.$message({
              showClose: true,
              message: this.$t('profileMessage.SteamError'),
              type: "error",
              customClass: "message-styles"
            });
          }
        });
    },
    // 获取饰品库存
    getSkins() {
      let param = {
        pagesize: this.pageSize,
        page: this.page,
      };
      var url = "/api/rucksack/packagelist";
      this.$axios.post(url, this.$qs.stringify(param)).then((res) => {
        var data = res.data;
        if (data.code == "1") {
          this.total = data.data.total;
          this.total_value = data.data.total_value;
          this.skinList = data.data.data;
        }
        if (data.msg == "无数据") {
          this.skinList = [];
        }
      });
    },
    getShortName(item) {
      const lang = this.language;
      switch (lang) {
        case "zh":
          return item.shortName;
        case "en":
          return item.english_shortName;
        default:
          return item.shortName;
      }
    },

    getExteriorName(item) {
      const lang = this.language;
      switch (lang) {
        case "zh":
          return item.exteriorName;
        case "en":
          return item.english_exteriorName;
        default:
          return item.exteriorName;
      }
    },
    // 兑换成金币
    getExchange(event, id) {
      event.stopPropagation();
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].state1) {
          this.$message({
            message: this.$t('profileMessage.sending'),
            type: "warning",
            customClass: "message-styles"
          });
          return;
        }
      }
      for (let i = 0; i < this.skinList.length; i++) {
        if (id == this.skinList[i].id) {
          this.skinList[i].loading1 = true;
          this.skinList[i].loading2 = true;
          this.checkedPrice = this.skinList[i].price;
        }
      }
      this.$forceUpdate();
      this.changeDecoration = [id];
      this.startExchange();
    },
    // 取回
    getPull(event, id, steamId) {
      event.stopPropagation();
      for (let i = 0; i < this.skinList.length; i++) {
        if (id == this.skinList[i].id) {
          this.skinList[i].loading1 = true;
          this.skinList[i].loading2 = true;
          this.skinList[i].state1 = true;
        }
      }
      this.$forceUpdate();
      id = [id].join();
      let param = {
        player_skins_ids: id,
        itemId: steamId,
        random: this.random,
      };
      this.$axios
        .post("/api/rucksack/skintosteam", this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.code == "1") {
            this.getList(this.page);
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
            });
          } else {
            var hint = "";
            if (data.msg.indexOf("余额不足") != -1) {
              hint = "取回错误，请联系客服";
            } else if (data.msg.indexOf("取回失败,请充值") != -1) {
              hint = "为防止机器人恶意注册，进行任意充值即可开箱提取哦~";
            } else {
              hint = data.msg;
            }
            this.$message({
              showClose: true,
              message: hint,
              type: "warning",
            });
            for (let i = 0; i < this.skinList.length; i++) {
              if (id == this.skinList[i].id) {
                this.skinList[i].loading1 = false;
                this.skinList[i].loading2 = false;
                this.skinList[i].state1 = false;
              }
            }
            this.$forceUpdate();
          }
          this.randomString();
        });
    },
    randomString(e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      this.random = n;
    },
    // 确认取回
    startExchange() {
      // 兑换钻石
      this.exchangeToMoney(this.changeDecoration.join());
      this.checkedPrice = 0;
    },
    /**
     * 兑换成 钻石
     * @param {*} id
     */
    exchangeToMoney(id) {
      let param = {
        player_skins_ids: id,
      };
      this.$axios
        .post("/api/rucksack/exchangetomoney", this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          if (data.code == "1") {
            this.getList();
            this.getSkins();
            this.$store.commit("getMoney", data.data.total_amount);
            Utils.$emit("masonry", data.data.total_amount);
            this.$message({
              showClose: true,
              message: this.$t('openBoxMessage.success'),
              type: "success",
              customClass: "message-styles"
            });
            this.checkedPrice = 0;
            this.changeDecoration = [];
          } else {
            for (let i = 0; i < this.skinList.length; i++) {
              if (id == this.skinList[i].id) {
                this.skinList[i].loading1 = false;
                this.skinList[i].loading2 = false;
              }
            }
            this.$forceUpdate();
            this.$message({
              showClose: true,
              message: this.$t('openBoxMessage.error'),
              type: "warning",
              customClass: "message-styles"
            });
          }
        });
    },
    //复制
    copyText(text) {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = text; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
  },
  mounted() {
    this.getList();
    this.getSkins();
  },
};
</script>
<style scoped lang="less">
.panel {
  color: #fff;
}
.openbox-container_header {
  color: #fff;
  width: 100%;
  //   min-width: 500px;
  height: 30px;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
  position: relative;
  box-sizing: border-box;

  .animation {
    position: absolute;
    right: 15%;
    top: 20px;
    display: flex;
  }

  .back {
    position: absolute;
    left: 15%;
    top: 20px;
    width: 43px * 1.2;
    height: 24px * 1.2;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../assets/img/open-box/back.png");
    color: #fff;
  }
}
.breadcrumb {
  display: flex;
  font-size: 14px;
  .cat-breadcrumb {
    color: #cedbea;
    cursor: pointer;
  }

  .ng-binding {
    display: flex;
    align-items: center;
    color: #727989;
    img {
      width: 20px;
    }
  }
}
.user-panel--content {
  background: #1e2335;
  padding: 40px 56px;
}
.user-info {
  // width: calc(100% - 12rem);
  padding: 40px 0px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.user-info .left,
.user-info .right {
  padding: 20px 0px;
}
.user-info .left {
  display: flex;
  align-items: center;
  border-right: 1px solid #3e4459;
  .info {
    position: relative;
    .name {
      font-weight: 700;
      line-height: 54px;
      font-size: calc(0.8vw + 0.5vh);
    }
    .steam-id {
      display: flex;
      font-size: calc(0.6vw + 0.3vh);
      .copy-span {
        display: block;
        width: 40px;
        height: 40px;
        background: url("../assets/img/profile/copy.svg") no-repeat 0% 0%;
        margin-left: 10px;
      }
    }
    .steam-profile {
      display: flex;
      .steam-profile-url {
        padding: 3px 25px;
        background: #404453;
        color: #fff;
        border-color: #404453;
        line-height: 17px;
        font-weight: 700;
        width: 120px;
        border-radius: 99px;
        text-align: center;
        a {
          color: #fff;
          text-decoration: none;
          font-size: 11px;
        }
      }
      .tippy {
        background: #484e62;
        height: 1.5rem;
        width: 1.5rem;
        display: grid;
        place-items: center;
        border-radius: 50px;
        margin-left: 10px;
      }
    }
    .blance {
      display: flex;
      align-items: center;
      padding: 30px 0 40px 90px;
      background: url("../assets/img/profile/wallet-ico.png") no-repeat 20px 50%;
      position: absolute;
      left: -40px;
      .panel-wallet-money {
        color: #dd4a4d;
        font-size: 28px;
        padding-left: 5px;
        padding-right: 15px;
        font-weight: 700;
      }
      .open-wallet {
        border: 1px solid #d04648;
        width: 35px;
        height: 35px;
        line-height: 29px;
        color: #fff;
        display: inline-block;
        font-size: 31px;
        font-weight: 400;
        text-align: center;
        border-radius: 50%;
        background: linear-gradient(
          to right,
          rgba(235, 75, 75, 0.1) 0%,
          rgba(235, 75, 75, 0.3) 100%
        );
        &:hover {
          background-color: #d04648;
        }
      }
    }
  }
}
.user-info .left,
.user-info .right {
  width: 50%;
}
.user-info .right {
  width: 46%;
  .user-counters {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -10px;
  }

  .user-counters > * {
    margin: 0 10px;
  }
  .counter-cases {
    background: 0 0;
    padding-left: 26px;
    left: -26px;
    padding-top: 14px;
    position: relative;
  }

  .counter-cases .font-big {
    color: #fff;
    padding-bottom: 4px;
  }

  .counter-cases .sep {
    border: none;
    padding-bottom: 25px;
  }

  .counter-cases .number {
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 300;
    padding: 10px 0 0 48px;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzEiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAzMSAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuMzMzMzcgMTAuNDI4NkwxNiA0LjMzMzM3TDI1LjEzMDcgOS41NTA5QzI1LjU5NzQgOS44MTc0NyAyNS45ODUzIDEwLjIwMjcgMjYuMjU1IDEwLjY2NzZDMjYuNTI0OCAxMS4xMzI0IDI2LjY2NjggMTEuNjYwMyAyNi42NjY3IDEyLjE5NzhWMTkuMzI2MUMyNi42NjY4IDE5Ljg2MzYgMjYuNTI0OCAyMC4zOTE1IDI2LjI1NSAyMC44NTYzQzI1Ljk4NTMgMjEuMzIxMiAyNS41OTc0IDIxLjcwNjQgMjUuMTMwNyAyMS45NzNMMTcuNTExNyAyNi4zMjY1QzE3LjA1MTMgMjYuNTg5NSAxNi41MzAyIDI2LjcyNzggMTYgMjYuNzI3OEMxNS40Njk4IDI2LjcyNzggMTQuOTQ4OCAyNi41ODk1IDE0LjQ4ODQgMjYuMzI2NUw2Ljg2OTM3IDIxLjk3M0M2LjQwMjcgMjEuNzA2NCA2LjAxNDgyIDIxLjMyMTIgNS43NDUwNiAyMC44NTYzQzUuNDc1MzEgMjAuMzkxNSA1LjMzMzI4IDE5Ljg2MzYgNS4zMzMzNyAxOS4zMjYxVjE1LjA1NjQiIHN0cm9rZT0iIzNENUFGRSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTE0LjU1NTUgMTUuNzQ2N0MxNC45OTk1IDE1Ljk4NTcgMTUuNDk1OSAxNi4xMTA5IDE2LjAwMDEgMTYuMTEwOUMxNi41MDQzIDE2LjExMDkgMTcuMDAwNyAxNS45ODU3IDE3LjQ0NDcgMTUuNzQ2N0wyNS45MDQ5IDExLjE5MDZNMTYuMDAwMSAxNi41MjM5VjI2LjQyODYiIHN0cm9rZT0iIzNENUFGRSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01LjMzMzM5IDEwLjQyODZMMTYuMDAwMSAxNi41MjM5TDExLjQyODYgMTguMDQ3N0wwLjc2MTk2MyAxMS45NTI0TDUuMzMzMzkgMTAuNDI4NlpNMTYuMDAwMSA0LjMzMzM5TDI2LjY2NjcgMTAuNDI4NkwyOS43MTQzIDcuMzgxTDE5LjA0NzcgMS4yODU3N0wxNi4wMDAxIDQuMzMzMzlaIiBzdHJva2U9IiMzRDVBRkUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgoK)
      0 11px no-repeat;
    background-size: 29px;
  }

  .counter-upgrades {
    color: #3d5afe;
    padding: 20px 35px 0;
  }

  .counter-upgrades {
    background-position: left 25px;
    padding-left: 26px;
    left: -26px;
    padding-top: 14px;
    position: relative;
  }

  .counter-upgrades .font-big {
    color: #fff;
    padding-bottom: 4px;
  }

  .counter-upgrades .sep {
    border: none;
    padding-bottom: 25px;
  }

  .counter-upgrades .number {
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 300;
    padding: 10px 0 0 48px;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyNiAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4IDIySDguMDAwMDNDNy40Njk2IDIyIDYuOTYwODkgMjIuMjEwNyA2LjU4NTgyIDIyLjU4NThDNi4yMTA3NCAyMi45NjA5IDYuMDAwMDMgMjMuNDY5NiA2LjAwMDAzIDI0VjI2QzYuMDAwMDMgMjYuNTMwNSA2LjIxMDc0IDI3LjAzOTIgNi41ODU4MiAyNy40MTQyQzYuOTYwODkgMjcuNzg5MyA3LjQ2OTYgMjggOC4wMDAwMyAyOEgxOEMxOC41MzA1IDI4IDE5LjAzOTIgMjcuNzg5MyAxOS40MTQyIDI3LjQxNDJDMTkuNzg5MyAyNy4wMzkyIDIwIDI2LjUzMDUgMjAgMjZWMjRDMjAgMjMuNDY5NiAxOS43ODkzIDIyLjk2MDkgMTkuNDE0MiAyMi41ODU4QzE5LjAzOTIgMjIuMjEwNyAxOC41MzA1IDIyIDE4IDIyWk0xOCAyNkg4LjAwMDAzVjI0SDE4VjI2Wk0yNS43MDcgMTIuMjkzTDEzLjcwNyAwLjI5MzAzMUMxMy41MTk1IDAuMTA1NTU5IDEzLjI2NTIgMC4wMDAyNDQxNDEgMTMgMC4wMDAyNDQxNDFDMTIuNzM0OSAwLjAwMDI0NDE0MSAxMi40ODA2IDAuMTA1NTU5IDEyLjI5MyAwLjI5MzAzMUwwLjI5MzAzMSAxMi4yOTNDMC4xNTMyMjIgMTIuNDMyOSAwLjA1ODAxNTIgMTIuNjExIDAuMDE5NDQ3NiAxMi44MDVDLTAuMDE5MTE5OSAxMi45OTkgMC4wMDA2ODMwOTIgMTMuMiAwLjA3NjM1MzEgMTMuMzgyN0MwLjE1MjAyMyAxMy41NjU0IDAuMjgwMTYzIDEzLjcyMTUgMC40NDQ1NzMgMTMuODMxNEMwLjYwODk4MiAxMy45NDEzIDAuODAyMjgxIDE0IDEuMDAwMDMgMTRINi4wMDAwM1YxOEM2LjAwMDU2IDE4LjUzMDMgNi4yMTE0NCAxOS4wMzg3IDYuNTg2NCAxOS40MTM3QzYuOTYxMzYgMTkuNzg4NiA3LjQ2OTc2IDE5Ljk5OTUgOC4wMDAwMyAyMEgxOEMxOC41MzAyIDE5Ljk5OTIgMTkuMDM4NSAxOS43ODgzIDE5LjQxMzQgMTkuNDEzNEMxOS43ODgzIDE5LjAzODUgMTkuOTk5MiAxOC41MzAyIDIwIDE4VjE0SDI1QzI1LjE5NzggMTQgMjUuMzkxMSAxMy45NDEzIDI1LjU1NTUgMTMuODMxNEMyNS43MTk5IDEzLjcyMTUgMjUuODQ4IDEzLjU2NTQgMjUuOTIzNyAxMy4zODI3QzI1Ljk5OTQgMTMuMiAyNi4wMTkyIDEyLjk5OSAyNS45ODA2IDEyLjgwNUMyNS45NDIgMTIuNjExIDI1Ljg0NjggMTIuNDMyOSAyNS43MDcgMTIuMjkzWk0xOCAxMlYxOEg4LjAwMDAzVjEySDMuNDE0MDNMMTMgMi40MTQwM0wyMi41ODYgMTJIMThaIiBmaWxsPSIjM0Q1QUZFIi8+Cjwvc3ZnPgoK)
      3px 0.9rem no-repeat;
    background-size: 23px;
  }
  label {
    display: block;
    font-size: 16px;
    line-height: 20px;
    padding: 14px 0 10px;
  }
  .tradeurl-help:hover {
    color: #999eb0;
  }
  .tradeurl-help {
    padding-left: 10px;
    font-size: 13px;
    color: #88909e;
    text-decoration: underline;
  }
  .Save {
    border: 2px solid #27ae60;
    color: #fff;
    min-width: 100px;
    display: block;
    width: 19%;
    margin-left: 2%;
    border-radius: 99px;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
    font-family: inherit;
    background: linear-gradient(
      to right,
      rgba(39, 174, 96, 0) 0%,
      rgba(39, 174, 96, 0.3) 100%
    );
    &:hover {
      background: #27ae60;
    }
  }
}
.form-tradeurl {
  display: flex;
}
.form-tradeurl /deep/.el-input__inner {
  border: 2px solid #4d515f;
  background: 0 0;
  padding: 13px 18px;
  color: #fff;
  font-size: 12px;
  border-radius: 50px;
}
.user-info .left .avatar {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-right: 40px;
}
.skins-box {
  background: #292f47;
  .tabs {
    margin-bottom: 30px;
  }
  .panel-tabs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .tab {
    display: block;
    float: left;
    box-sizing: border-box;
    padding: 10px;
    font-size: 16px;
    border-bottom: 2px solid rgba(113, 122, 142, 0.35);
    font-weight: 700;
    cursor: pointer;
  }

  .tab.selected {
    color: #fff;
    border-bottom: 2px solid #3d5afe;
    cursor: default;
  }
  .empty {
    color: #fff;
    padding: 60px;
    text-align: center;
    font-weight: 700;
  }
}
.counter-cases {
  color: #3d5afe;
  padding: 20px 35px 0;
}
.box-list {
  padding: 20px 16px 16px 16px;
  background-color: #292f47;

  .boxlist-top {
    height: 40px !important;
    display: flex;
    width: max-content;
    justify-content: center;
    margin: 10px auto 20px auto;
    //   border-bottom: 1px solid #f3ab34;
    //   background-color: #f3ab34;
    border: 1px solid #283347;
    border-radius: 50px;
    align-items: center;
    padding-bottom: 1px;
    overflow: hidden;

    .left {
      span {
        font-size: 14px;
        color: #c3c3e2;
        display: inline-block;
        height: 40px;
        line-height: 40px;
        width: 120px;
        text-align: center;
      }

      .span1 {
        //   border-bottom: 2px solid #f3ab34;
        background-color: #f3ab34;
        color: #000;
        border-radius: 50px;
        // font-weight: bold;
        //   background-image: url("../assets/new/bg_border.png");
      }

      span:hover {
        cursor: pointer;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .right-one,
      .right-two {
        display: flex;
      }

      .right-data {
        display: flex;
        align-items: center;

        .percent {
          margin-right: 15px;
          display: flex;
          align-items: center;

          span {
            display: table;
            width: 26px;
            height: 7px;
            border-radius: 5px;
          }

          .span1 {
            background-color: #0f0f0f;
          }

          .span11 {
            color: #0e0d0d;
          }

          .span2 {
            background-color: #b969d4;
          }

          .span12 {
            color: #b969d4;
          }

          .span3 {
            background-color: #adc8cd;
          }

          .span13 {
            color: #adc8cd;
          }
        }
      }
    }
  }

  .boxlist-bot {
    zoom: 0.64;
    padding: 10px 24%;

    ul {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(160px, 160px));
      gap: 8px;

      li {
        width: 100%;
        background-size: 100% auto;

        background-repeat: no-repeat;
        cursor: pointer;

        .boxlist-warp {
          // background-color: #24252f;
          margin: 8px;
          border-radius: 5px;
          display: flex;
          position: relative;
          flex-direction: column;
          justify-content: space-between;
          height: calc(100% - 20px);

          .boxlist1-top {
            padding: 0 20px;
            background-size: 100% 100%;
            text-align: center;
            // border-bottom: 3px solid #f1a920;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            // box-shadow: 0px 5px 10px #f1a920;
            img {
              width: 100%;
              margin-top: 20%;
              height: auto;
              min-height: 100px;
              cursor: pointer;
            }
          }

          .boxlist1-bot {
            padding: 8px;
            color: #c3c3e2;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 14px;
            white-space: nowrap;
            // word-wrap: break-word;
          }

          .boxlist1-bot1 {
            padding: 8px;
            display: flex;
            align-items: center;

            img {
              width: 25px;
              height: 25px;
              border-radius: 50%;
            }

            span {
              margin-left: 8px;
              color: #c3c3e2;
              font-size: 14px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }

          .list-pirce {
            padding: 0px 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .ico {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 12px;
            color: #04f540;
            background-color: rgba(0, 0, 0, 0.5);
            padding: 2px 4px;
          }

          .pirce-left {
            display: flex;
            align-items: center;
            padding-right: 10px;
            font-size: 13px;
            color: #a5aabd;

            img {
              width: auto;
              height: 15px;
              margin-right: 5px;
            }

            & > div {
              flex: 1;
              color: #f4bd50;
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              span:last-child {
                color: #c3c3e2;
              }
            }
          }

          .pirce-right {
            color: #848492;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .boxlist-botm {
    zoom: 0.64;
    padding: 0 0px;

    ul {
      width: 100%;
      display: grid;
      grid-gap: 10px 0;
      justify-content: space-between;
      grid-template-columns: repeat(auto-fill, 32%);

      li {
        width: 100%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        cursor: pointer;

        .boxlist-warp {
          // background-color: #24252f;
          margin: 12px;
          border-radius: 5px;
          display: flex;
          position: relative;
          flex-direction: column;
          justify-content: space-between;
          height: calc(100% -20px);

          .boxlist1-top {
            padding: 0 20px;
            background-size: 100% 100%;
            text-align: center;
            // border-bottom: 3px solid #f1a920;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            // box-shadow: 0px 5px 10px #f1a920;
            img {
              width: 100%;
              margin-top: 10%;
              height: auto;
              cursor: pointer;
            }
          }

          .boxlist1-bot {
            padding: 8px;
            color: #c3c3e2;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 10px;
            white-space: nowrap;
            // word-wrap: break-word;
          }

          .boxlist1-bot1 {
            padding: 8px;
            display: flex;
            align-items: center;

            img {
              width: 25px;
              height: 25px;
              border-radius: 50%;
            }

            span {
              margin-left: 8px;
              color: #c3c3e2;
              font-size: 14px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }

          .list-pirce {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .ico {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 12px;
            color: #04f540;
            background-color: rgba(0, 0, 0, 0.5);
            padding: 2px 4px;
          }

          .pirce-left {
            display: flex;
            align-items: center;
            padding-right: 10px;
            font-size: 13px;
            color: #a5aabd;

            img {
              width: auto;
              height: 15px;
              margin-right: 5px;
            }

            & > div {
              flex: 1;
              color: #f4bd50;
              font-size: 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              span:last-child {
                color: #c3c3e2;
              }
            }
          }

          .pirce-right {
            color: #848492;
            font-size: 10px;
          }
        }
      }
    }
  }

  .boxlist-botsm {
    // zoom: 0.64;
    padding: 10px 24%;

    ul {
      width: 100%;

      li {
        width: 100%;
        // background-size: 100% 100%;
        // background-repeat: no-repeat;
        // cursor: pointer;
        height: 45px !important;
        background: rgba(22, 26, 37, 1);
        border-radius: 3px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 12px;

        .boxlist-warp {
          // background-color: #24252f;
          width: 100%;
          padding: 12px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .boxlist1-top {
            padding: 0 20px;
            text-align: center;
            border-radius: 8px;
            display: flex;
            align-items: center;
            // border-bottom: 3px solid #f1a920;

            // box-shadow: 0px 5px 10px #f1a920;
            img {
              width: 24px !important;
              border-radius: 8px;
              height: auto;
            }

            span {
              width: 130px;
              color: white;
              margin-left: 8px;
              color: #c3c3e2;
              font-size: 12px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }

          .boxlist1-top:last-child {
            display: flex;
            margin-left: 0 !important;

            img {
              width: 32px !important;
              border-radius: 8px;
              height: auto;
            }
          }

          .boxlist1-bot {
            padding: 8px;
            color: #c3c3e2;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 10px;
            white-space: nowrap;
            // word-wrap: break-word;
          }

          .boxlist1-bot1 {
            width: 60px;
            padding: 8px;
            display: flex;
            font-size: 12px;
            align-items: center;
            margin-left: 5px !important;
            color: white !important;

            img {
              width: 14px;
              height: 14px;
              border-radius: 50%;
              margin-right: 6px !important;
            }

            span {
              margin-left: 8px;
              font-size: 14px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }

          .list-pirce {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .ico {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 12px;
            color: #04f540;
            background-color: rgba(0, 0, 0, 0.5);
            padding: 2px 4px;
          }

          .pirce-left {
            display: flex;
            align-items: center;
            padding-right: 10px;
            font-size: 13px;
            color: #a5aabd;

            img {
              width: auto;
              height: 15px;
              margin-right: 5px;
            }

            & > div {
              flex: 1;
              color: #f4bd50;
              font-size: 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              span:last-child {
                color: #c3c3e2;
              }
            }
          }

          .pirce-right {
            color: #848492;
            font-size: 10px;
          }
        }
      }
    }
  }
}
.xiangzi {
  width: 100%;
  // max-width: 90%;
  margin: auto;

  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      width: 200px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      cursor: pointer;
      margin-top: 12px;
      position: relative;
      .boxlist-warp {
        background: url("../assets/img/openBox/item-bg.jpg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        // padding: 8px;
        border-radius: 5px;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 20px);
        position: relative;
        box-sizing: border-box;
        // padding-bottom: 10px;

        .boxlist1-top {
          padding: 0 20px;
          background-size: 100% 100%;
          text-align: center;
          // border-bottom: 3px solid #f1a920;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          // padding: 15% 10% 50% 10%;
          height: 110px;
          overflow: hidden;
          // position: absolute;
          // top: 0;
          // bottom: 0;
          // left: 0;
          // right: 0;
          // margin: auto;

          // box-shadow: 0px 5px 10px #f1a920;
          img {
            // width: 70%;
            height: 100%;
            cursor: pointer;
          }
        }
        .boxlist1-bottom {
          // height: 56px;
          background: #131724;
          padding: 8px 0px;
        }
        .boxlist1-bot {
          width: 90%;
          margin: auto;
          color: white;
          font-family: "微软雅黑";
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          // line-height: 24px;
          font-size: 14px;
          font-weight: bold;
          word-wrap: break-word;
        }

        .boxlist1-bot2 {
          height: 16px;
          width: 90%;
          margin: auto;
          color: rgb(204, 204, 204);
          font-family: "微软雅黑";
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 14px;
          word-wrap: break-word;
        }

        .boxlist1-bot1 {
          padding: 8px;
          display: flex;
          align-items: center;

          img {
            width: 25px;
            height: 25px;
            border-radius: 50%;
          }

          span {
            margin-left: 8px;
            color: #c3c3e2;
            font-size: 14px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        .list-pirce {
          padding: 6px 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #131724;
          line-height: 40px;
        }

        .ico {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 12px;
          color: #04f540;
          background-color: rgba(0, 0, 0, 0.5);
          padding: 2px 4px;
        }

        .pirce-left {
          display: flex;
          align-items: center;
          // padding-right: 10px;
          font-size: 11px;
          color: #fff;
          // font-weight: 700;
          border: 2px solid #27ae60;
          background: linear-gradient(
            to right,
            rgba(39, 174, 96, 0) 0%,
            rgba(39, 174, 96, 0.3) 100%
          );
          border-radius: 99px;
          text-align: center;
          padding: 0px 10px;
          &:hover {
            background: #27ae60;
          }
          .shopping-cart {
            background: url("../assets/img/profile/shopping-cart.svg") no-repeat
              left 50%;
            background-size: 70% 70%;
            width: 20px;
            height: 20px;
          }
          .shopping-price {
            line-height: 20px;
          }
        }

        .pirce-right {
          color: #a5aabd !important;
          // font-weight: 700;
          // font-size: 13px;
          font-family: "微软雅黑";
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &:hover span {
            background: #3d5afe;
          }
          span {
            text-decoration: none;
            display: block;
            border: 2px solid #3d5afe;
            border-radius: 99px;
            text-align: center;
            background: linear-gradient(
              to right,
              rgba(61, 90, 254, 0) 0%,
              rgba(61, 90, 254, 0.3) 100%
            );
            min-width: 74px;
            line-height: 22px;
            color: #fff;
            font-size: 11px;
            // margin: 6px 0 0 5px;
          }
        }
        .upGrade {
          position: absolute;
          width: 1.75rem;
          height: 1.75rem;
          overflow: hidden;
          transition: width 0.2s;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          left: 5%;
          top: 26%;
          bottom: unset;
          line-height: 22px;
          z-index: 80;
          background: linear-gradient(
            to right,
            rgba(207, 133, 25, 0) 0%,
            rgba(207, 133, 25, 0.3) 100%
          );
          color: #fff;
          border: 2px solid #cf8519;
          border-radius: 99px;
          svg {
            position: absolute;
            left: 0.75rem;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 0.75rem;
            height: 0.75rem;
            line-height: 22px;
          }
          div {
            position: absolute;
            top: 50%;
            left: 1.675rem;
            transform: translateY(-50%);
            color: #fff;
            line-height: 1.75rem;
            font-size: 11px;
          }
          &:hover {
            width: 5.25rem;
            background: #cf8519;
          }
        }
      }
      .border {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -0.375rem;
        width: 0.375rem;
        z-index: 0;
      }
    }
  }
}
.gradient-color-\#007acc {
  background-image: linear-gradient(to bottom, #4b69ff 0, #2740bb 100%);
}
// 紫色
.gradient-color-\#8a2be2 {
  background-image: linear-gradient(to bottom, #8847ff 0, #5e29bf 100%);
}
// 金色
.gradient-color-\#FFA500 {
  background-image: linear-gradient(to bottom, #ffae39 0%, #b46d06 100%);
}
// 红色
.gradient-color-\#FF4500 {
  background-image: linear-gradient(to bottom, #e34343 0%, #ca2d2d 100%);
}
@media screen and (max-width: 768px) {
  .user-panel--content {
    padding: 30px 20px;
  }
  .user-info {
    display: block;
    padding: 0px;
    margin-top: 0px;
  }
  .user-info .left,
  .user-info .right {
    width: 100%;
  }
  .user-info .left {
    padding-bottom: 0px;
    border-right: none;
    .name {
      font-size: 20px !important;
    }
    .steam-id {
      font-size: 14px !important;
      color: #c9d1de;
    }
    .avatar {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      margin-right: 20px;
    }
  }
  .catalyst__action {
    // margin-top: 24px;
    display: flex;
    gap: 8px;
  }
  .catalyst__action--steam {
    width: 50%;
    height: 30px;
    border-radius: 99px;
    background: #404453;
    text-align: center;
    a {
      text-decoration: none;
    }
  }
  .catalyst__action--steam a {
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    line-height: 29px;
    color: #fff;
  }
  .catalyst__action--help div {
    margin-top: 7px;
    margin-left: 0px;
    display: inline-block;
    width: 16px;
    height: 16px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    border-radius: 99px;
    text-align: center;
    border: 2px solid #c9d1de;
    color: #c9d1de;
    opacity: 0.25;
  }
  .catalyst__balance {
    display: flex;
    justify-content: flex-start;
    border-width: 1px 0px 1px 0px;
    border-style: solid;
    border-color: #343a4a;
    height: 72px;
    width: 100%;
    margin-top: 32px;
    align-items: center;
    margin-bottom: 24px;
    gap: 10px;
  }
  .catalyst__balance--label {
    font-size: 14px;
    font-weight: 700;
    color: #fff;
  }
  .catalyst__balance--value {
    font-weight: 700;
    font-size: 20px;
    color: #d04648;
    display: flex;
    flex-flow: row;
    align-items: center;
    column-gap: 16px;
  }
  .plus-button {
    font-size: 31px;
    border: 1px solid #d04648;
    margin-left: -3px;
    width: 32px;
    height: 32px;
    color: #fff;
    display: inline-block;
    font-size: 31px;
    line-height: 26px;
    font-weight: 400;
    background: linear-gradient(
      to right,
      rgba(235, 75, 75, 0.1) 0%,
      rgba(235, 75, 75, 0.3) 100%
    );
    border-radius: 50%;
    text-align: center;
    font-family: inherit;
  }
  .user-info .right{
    padding: 0px !important;
    .ng-binding{
      font-size: 14px;
      color: #727989;
    }
    .form-tradeurl{
      display: block;
    }
    .form-tradeurl .Save{
      margin: 20px 0px;
    }
    .form-tradeurl /deep/.el-input__inner{
      width: 100%;
    }
  }
  .xiangzi ul{
    justify-content: center;
    li{
      width: 214px;
    }
  }
}
</style>